import React, { useState, useEffect } from 'react';
import { Typography, message, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import styled from '@emotion/styled';
import { primaryColor } from '@shorthand/theme';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const { Text, Title } = Typography;
const MESSAGE_DURATION = 2500;

export function CopyableLink({ url, urlShort, children, note, alert }: any) {
  const [interactionMessage, setInteractionMessage] = useState(note || "");
  const tooltipTitle = interactionMessage?.length > 0 ? interactionMessage : (note || 'Copy');
  const onCopy = () => {
    message.success(alert || 'Link Copied!');
    setInteractionMessage('Copied!');
  };

  useEffect(() => {
    if (interactionMessage?.length > 0) {
      const to = setTimeout(() => setInteractionMessage(''), MESSAGE_DURATION);
      return () => clearTimeout(to);
    }
    return;
  }, [interactionMessage]);
  return (
    <>
      { /* @ts-ignore */}
      <CopyToClipboard text={url} onCopy={onCopy}>
        <Tooltip title={tooltipTitle}>
          <LinkContainer>
            <Text
              style={{
                verticalAlign: 'text-bottom',
                margin: 0,
                fontSize: 16
              }}
              // copyable={{ text: clientUrl }}
              ellipsis>
              {urlShort || children}
            </Text>
            <CopyOutlined
              style={{
                color: primaryColor(1),
                fontSize: 18,
                marginLeft: 10
              }}
            />
          </LinkContainer>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
}

const LinkContainer = styled.div`
  display: flex;
  flex: 0;
  /* margin-top: 10px; */
  padding: 10px;
  margin: 10px;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: ${primaryColor(0.2)};
  border-radius: 10px;
  cursor: pointer;
`;
