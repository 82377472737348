import { CopyOutlined, EnterOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { message, Tooltip, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CopyBlock, dracula } from "react-code-blocks";
import { gradients, medGreen, white } from "@shorthand/theme"
import _ from 'lodash'
import { SHBadge } from  "@shorthand/atoms"
const { materialDark } = require('react-syntax-highlighter/dist/cjs/styles/prism');

const MESSAGE_DURATION = 1500;
const { Text } = Typography

const exampleFormula = `=SH.GET("dev123", ${40}, Sample!$D$4:K$4, D4:K4)`

export type CodeBlockAction = {
  icon: ReactNode,
  name: string,
  after: string,
  onAction: () => any,
  actionGradient?: string
}

export const CopyableCodeBlock = ({ formula=exampleFormula, actions }: {formula: string, actions?: CodeBlockAction[]}) => {
  const [interactionMessage, setInteractionMessage] = useState<string>();
  const [interactionMessageBG, setInteractionMessageBG] = useState<string>();
  // const tooltipTitle = !_.isEmpty(interactionMessage) ? interactionMessage : ('Copy to clipboard');
  
  useEffect(() => {
    if (!_.isEmpty(interactionMessage)) {
      const to = setTimeout(() => setInteractionMessage(undefined), MESSAGE_DURATION);
      return () => clearTimeout(to);
    }
    return;
  }, [interactionMessage]);

  useEffect(() => {
    if (!_.isEmpty(interactionMessageBG)) {
      const to = setTimeout(() => setInteractionMessageBG(undefined), MESSAGE_DURATION);
      return () => clearTimeout(to);
    }
    return;
  }, [interactionMessageBG]);

  const onCopy = () => {
    setInteractionMessage('Copied formula to clipboard!');
  };


  return (
    
      <Container>
      
        <HighlighterContainer>
          { /* @ts-ignore */}
          <SyntaxHighlighter
            // language="vbnet" 
            // wrapLines
            customStyle={{ 
              margin: 0, 
              maxWidth: '100%',
              overflowY: 'hidden',
              overflowX: 'scroll',
              bottom: -17,
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
            language="excel-formula"
            style={materialDark}
            >
            {formula}
          </SyntaxHighlighter>
        </HighlighterContainer>

        <CopyButtonContainer>
          {
            actions?.map((action) => (
              <Tooltip title={action.name} key={action.name}>
                <CopyButton 
                  key={action.name}
                  onClick={async () => {
                    await action.onAction()
                    setInteractionMessageBG(action.actionGradient || gradients.primary)
                    setInteractionMessage(action.after);
                  }}>
                  <ActionIconSpan>
                    { action.icon }
                  </ActionIconSpan>
                </CopyButton>
              </Tooltip>
            ))
          }
          
          <Tooltip title={'Copy formula to clipboard'}>
            { /* @ts-ignore */}
            <CopyToClipboard text={formula} onCopy={onCopy}>
              <CopyButton>
                <ActionIconSpan>
                  <CopyOutlined />
                </ActionIconSpan>
              </CopyButton>
              
            </CopyToClipboard>
          </Tooltip>
        </CopyButtonContainer>
        { !_.isEmpty(interactionMessage) &&
          (
            <InteractionTextContainer 
              style={{ opacity: 0.78 }}
              background={interactionMessageBG}
              interactionMessage={interactionMessage}>
              <SHBadge>
                { interactionMessage }
              </SHBadge>
            </InteractionTextContainer>
          )
        }
      </Container>
  )
}

const ActionIconSpan = styled.span`
  color: white;
  font-size: 24px;
`

const CopyButton = styled.a`
  opacity: 0.7;
  color: white;
  font-size: 24px;
  border-radius: 4px;
  background: ${white(0.3)};
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    opacity: 1;
    background: ${white(0.3)};
  }
  padding: 6px;
  margin-left: 10px;
`

const Container = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  max-height: 56px;
  display: flex;
  max-width: 100%;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); 
  /* this fixes the overflow:hidden in Chrome/Opera */
`

const CodeBlockContainer = styled.div`
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  align-items: stretch;
  position: relative;
  flex: 1;
  display: flex;
`

const HighlighterContainer = styled.div`
  max-width: 100%;
  min-width: 100%;
  align-items: stretch;
  flex-direction: column;
  display: flex;
  flex: 1;
  min-height: 56px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`


const InteractionTextContainer = styled.div<any>`
  position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  color: white;
  align-items: center;
  flex-direction: row;
  left: 10px;
  right: 100px;
  top: 10px;
  bottom: 10px;
`

const CopyButtonContainer = styled.div<any>`
  position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  
  /* opacity: 0.5;
  background: ${({ interactionMessage }) => interactionMessage ? medGreen(0.5) : white(0)};

  :hover {
    opacity: 1;
    background: ${({ interactionMessage }) => interactionMessage ? medGreen(0.5) : white(0.1)};
  } */
  right: 0px;
  padding-right: 1rem;
  top: 0px;
  bottom: 0px;
`
