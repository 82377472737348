import styled from '@emotion/styled';

/* eslint-disable-next-line */
export interface SHValueHooksEditorAppProps {
  id: string
}

const StyledSHValueHooksEditorApp = styled.div`
  color: pink;
`;

export function SHValueHooksEditorApp(props: SHValueHooksEditorAppProps) {
  return (
    <StyledSHValueHooksEditorApp>
      <h1>Welcome to SHValueHooksEditorApp!</h1>
    </StyledSHValueHooksEditorApp>
  );
}

export default SHValueHooksEditorApp;
