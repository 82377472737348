import { 
  addDoc, 
  setDoc, 
  doc, 
  getDoc, 
  onSnapshot, 
  Firestore, 
  DocumentReference, 
  DocumentData, 
  query, 
  where, 
  limit,
  getDocs,
  orderBy,
  CollectionReference,
} from "firebase/firestore"; 
import { getDocPathString, getDatumByIndex, SHValue, SHValueDocument, SHValueIndexer, TaggedTopicName, deserializeSHDocument, getTopicHistoryCollectionPathString, SHDataDocToMatrix, SHValueScalar, SHValuedDocument, SHValueAuthor } from '@shorthand/data'
import { collection, firestore } from "@shorthand/data";
import { useState, useCallback } from 'react';
import moment from 'moment'
import _ from 'lodash';

export type ShorthandGetHandler = (a: SHValueDocument) => any

export type ShorthandIndexedValueHandler = (a: SHValue | undefined) => any

export type ShorthandListenerHandler<T=SHValueDocument> = (a: T | undefined) => any

export type ShorthandValueHandler = ShorthandListenerHandler<SHValueScalar[][]>

export type ShorthandValueDocHandler = ShorthandListenerHandler<SHValueDocument>

export type SHDomainClientSettings = {
  defaultDomainID: string,
  author?: SHValueAuthor
}

export const DefaultSHDomainClientSettings = {
  defaultDomainID: "dev"
}

export const createValueListenerFactory = (ref_: DocumentReference<DocumentData>) => (handler: ShorthandValueHandler, onError?: ErrorHandler) => {
  return onSnapshot(
    ref_, 
    (doc: any) => {
      const data_ = doc.data()
      try {
        if (!data_) {
          handler(undefined)
          return 
        }
        const data = deserializeSHDocument(data_) as SHValueDocument
        handler(data?.value === undefined ?  undefined : SHDataDocToMatrix(data))
      } catch (e: any) {
        console.error(e)
        onError && onError(e)
      }
    },
    (e) => {
      onError && onError(e)
    }
  )
}

export type SHValueExtractor = (a: SHValueDocument) => SHValue

export const createListenerFactory = (ref_: DocumentReference<DocumentData>) => (handler: ShorthandValueDocHandler, options?: { onError?: ErrorHandler, processValue?: SHValueExtractor}) => {
  return onSnapshot(
    ref_, 
    (doc: any) => {
      const data_ = doc.data()
      try {
        if (!data_) {
          handler(undefined)
          return 
        }
        const data = deserializeSHDocument(data_) as SHValueDocument
        handler({
          ...data,
          value: (options?.processValue || SHDataDocToMatrix)(data)
        })
      } catch (e: any) {
        console.error(e)
        options?.onError && options?.onError(e)
      }
    },
    (e) => {
      options?.onError && options?.onError(e)
    }
  )
}

export const createDocListenerFactory = (ref_: DocumentReference<DocumentData>) => 
  <T extends SHValuedDocument>(handler: ShorthandListenerHandler<T>, onError?: ErrorHandler) => {
  return onSnapshot(
    ref_, 
    (doc: any) => {
      const data_ = doc.data()
      try {
        if (!data_) {
          handler(undefined)
          return 
        }
        const doc = deserializeSHDocument(data_) as T
        handler(doc)
      } catch (e: any) {
        console.error(e)
        onError && onError(e)
      }
    },
    (e) => {
      onError && onError(e)
    }
  )
}

export type ErrorHandler = (e: { message?: string, code?: string | number, name?: string }) => any

export const ShorthandGetFactory = ({ firestore, doc_ }: { firestore: Firestore, doc_: typeof doc }) => ({ defaultDomainID }: SHDomainClientSettings=DefaultSHDomainClientSettings) => ({ valueID, tag }: TaggedTopicName) => (indexer?: SHValueIndexer) => {
  const docPath = getDocPathString({
    domainID: defaultDomainID,
    valueID,
  })
  
  const ref = doc_(
    firestore,
    docPath
  )

  const createListener = (handler: ShorthandGetHandler, onError?: ErrorHandler) => {
    return onSnapshot(
      ref, 
      (d) => {
        const data = d.data() as SHValueDocument
        try {
          handler(data)
        } catch (e: any) {
          console.error(e)
          onError && onError(e)
        }
      },
      (e) => {
        onError && onError(e)
      }
    )
  } 

  const createValueListener = (handler: ShorthandIndexedValueHandler, onError?: ErrorHandler) => {
    return onSnapshot(
      ref, 
      (d) => {
        const data = d.data() as SHValueDocument
       
        try {
          if (!data) {
            handler(undefined)
            return 
          }
          handler(
            getDatumByIndex(data)(indexer)
          )
        } catch (e: any) {
          console.error(e)
          onError && onError(e)
        }
      },
      (e) => {
        onError && onError(e)
      }
    )
  } 

  return ({
    ref,
    createListener,
    createValueListener
  })
}
  

export const ShorthandGet_ = ShorthandGetFactory({ firestore, doc_: doc })

export const isNullAuthorAuthor = ({ email }: { email: string }) => {
  if (!email) return true

  const trimmed = email.trim()
  if (_.isEmpty(trimmed)) return true

  const caseInsensitive = trimmed.toLowerCase()
  if (caseInsensitive === 'any') return true
  if (caseInsensitive === 'all') return true

  return false
}

export const ShorthandGet = ({ defaultDomainID }: SHDomainClientSettings=DefaultSHDomainClientSettings) => ({ valueID, tag }: TaggedTopicName) => (indexer?: SHValueIndexer) => {
  const docPath = getDocPathString({
    domainID: defaultDomainID,
    valueID,
    tag
  })
  
  const baseTopicRef = doc(
    firestore,
    docPath,
  )

  const ref = doc(
    firestore,
    docPath
  )

  const historyCollectionPath = getTopicHistoryCollectionPathString({
    domainID: defaultDomainID,
    valueID,
  })

  const historyRef = collection(
    firestore,
    historyCollectionPath
  )

  const getTaggedDocRef = (tagID: string) => doc(
    firestore,
    getDocPathString({
      domainID: defaultDomainID,
      valueID,
      tag: tagID
    })
  )

  const getArchiveQueryListener = ({
    asOf,
    tagID,
    author
  }:
  {
    asOf: moment.Moment,
    tagID?: string,
    author?: Partial<SHValueAuthor>

  }) => {
    const updatedTS = asOf.valueOf()
    const nowTS = moment().valueOf()

    const queryLast = query(
      historyRef, 
      ..._.compact([
        where('updatedTS', '<=', updatedTS),
        author?.email ? where('author.email', '==', author.email) : undefined,
      ]),
      orderBy('updatedTS', 'desc'),
      limit(1)
    )

    return (handler: ShorthandValueDocHandler, onError?: ErrorHandler) => {

      if (nowTS < updatedTS) {
        console.error("Realtime GET-HISTORICAL not yet implemented")
        // onSnapshot(qeueryLast)
      }
      // if looking for stuff in the past
      const q = getDocs(
        queryLast
      ).then((d) => {
        const first = _.first(d.docs);
        const data_ = first?.data()
        try {
          if (!data_) {
            handler(undefined)
            return 
          }
          const data = deserializeSHDocument(data_) as SHValueDocument
          handler(data?.value === undefined ?  undefined : data)
        } catch (e: any) {
          onError && onError(e)
          console.error(e)
        }
      }).catch(e => {
        console.error(e)
        onError && onError(e)
      })

      return () => {
        
      }
    }
  }
  

  const createIndexedValueListener = (handler: ShorthandIndexedValueHandler, onError?: ErrorHandler) => {
    return onSnapshot(
      ref, 
      (d) => {
        const data_ = d.data()
       
        try {
          if (!data_) {
            handler(undefined)
            return 
          }

          const data = deserializeSHDocument(data_) as SHValueDocument
          // handler(JSON.stringify({ value: data?.value, indexer, out: getDatumByIndex(data)(indexer), dims: data_.vectorDimensions }))
          // return 
          handler(
            getDatumByIndex(data)(indexer)
          )
        } catch (e: any) {
          console.error(e)
          onError && onError(e)
        }
      },
      (e) => {
        onError && onError(e)
      }
    )
  } 

  const createListener = createListenerFactory(ref)
  const createValueListener = createValueListenerFactory(ref)

  return ({
    historyRef,
    docPath,
    ref,
    baseTopicRef,
    getTaggedDocRef,
    getArchiveQueryListener,
    createListener,
    createIndexedValueListener,
    createValueListener
  })
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseAuthUser {
  count: number;
  increment: () => void;
}

export function useAuthUser(): UseAuthUser {
  const [count, setCount] = useState(0);
  const increment = useCallback(() => setCount((x) => x + 1), []);
  return { count, increment };
}

export default useAuthUser;
