import { SHDataDocToMatrix, classifySHDimensions, getSHValueDimensions } from '@shorthand/data';
import { apply2D, compactifyMatrix, initialize2D, isLastColNonNull, isLastRowNonNull, parseScalarToVal } from '@shorthand/utils';
import { SHValue, SHValueScalar } from '@shorthandai/core';
import React, { useMemo } from 'react';
import _ from 'lodash'
import  Spreadsheet from 'react-spreadsheet';
import hash from 'object-hash'

export const didMatrixExpand = (mat: (SHValueScalar)[][] ) => {
  const vectorDimensions = getSHValueDimensions(mat)
  for (var i = 0; i < vectorDimensions.X; i++) {
    if (!_.isNil(mat[i][vectorDimensions.Y - 1])) return true
  }
  for (var j = 0; j < vectorDimensions.Y; j++) {
    if (!_.isNil(mat[vectorDimensions.X - 1][j])) return true
  }
  return false
}

export function SHArrayEditor({ 
  value, 
  onChange,
  autoExpand=true,
  parseScalar=parseScalarToVal
}: { 
  value: SHValue; 
  onChange: (v: SHValue) => void; 
  autoExpand?: boolean,
  parseScalar?: typeof parseScalarToVal
}) {
  
  const vectorDimensions = getSHValueDimensions(value)
  const matrix = SHDataDocToMatrix({ value, vectorDimensions })
  const targetDims = autoExpand ? { 
    X: Math.max(3, isLastRowNonNull(matrix) ? vectorDimensions.X + 1 : vectorDimensions.X), 
    Y: Math.max(3, isLastColNonNull(matrix) ? vectorDimensions.Y + 1 : vectorDimensions.Y)
  } : vectorDimensions
  
  const _value = initialize2D(targetDims.X, targetDims.Y, (i, j) => ({ value: matrix?.[i]?.[j] }) )
  return(
    <>
      <Spreadsheet 
        data={_value} 
        hideColumnIndicators
        hideRowIndicators 
        onChange={(_mat) => {
          const data = apply2D(_mat, (c) => parseScalar(c?.value))
          if (hash(data) === hash(value)) return 
          onChange(compactifyMatrix(data))
        }} 
        />
    </>
  )
}
