import styled from "@emotion/styled"
import { SHValue, valueBadgeShortString } from "@shorthand/data"
import { Tooltip, Typography } from "antd"
import { getValueBG, deepBlue } from "@shorthand/theme";
import _ from 'lodash'

const { Text } = Typography 
export const SHValueBadge = ({ value }: { value?: SHValue }) => {
  const bg = getValueBG({ value: value }) 
  // const fontSize = getValueFont(value) || 12

  const { str, dimClass } = valueBadgeShortString({ value })()
  // const tooltip = `tooltip placeholder {valueStr}`

  return (
    <Tooltip title={`${dimClass}: ${str}`}>
      <ValueBadgeContainer background={bg}>
        <Text 
          style={{ color: 'white',  }} 
          // @ts-ignore
          ellipsis={{ expandable: true, rows: 1, }}>      
          { str }
        </Text>
      </ValueBadgeContainer>
    </Tooltip>
  )
}


const ValueBadgeContainer = styled.div<any>`
  min-height: 100%;
  padding: 0px 10px;
  min-width: 90px;
  max-width: 200px;
  background: ${({ background }) => background || deepBlue(1)};
  flex: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border-radius: 4px;
  color: white;
`
