import { CloseCircleOutlined, SettingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useAuthUserContext } from '@shorthand/hooks';
import { appBackground, gradients, medGreen, primaryColor, white } from '@shorthand/theme';
import { VERSION_STRING } from '@shorthand/theme/brand';
import { Button, Divider, Drawer, message, Space, Typography } from 'antd';
import { CSSProperties, useState } from 'react';
import { animated, useSpring } from 'react-spring'

const { Title, Text } = Typography

/* eslint-disable-next-line */
export interface SettingsWidgetProps {
  style?: CSSProperties
}

const StyledSettingsWidget = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 40px;
  font-size: 20px;
  padding: 4px;
  border-radius: 4px;
  /* border: 1px solid ${primaryColor(1)}; */
  color: white;
  background: ${gradients.primary};
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
`;

const OverlayTopContentContainer = styled(animated.div)`
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  display: flex;
`
  
const OverlayBottomContentContainer = styled(animated.div)`
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  flex: 0;
  display: flex;
`

const OverlayContainer = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${white(1)};
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 1rem;
`
  


export function SettingsWidget(props: SettingsWidgetProps) {
  const [ isOpen, setIsOpen ] = useState(false)
  const { user, signOut } = useAuthUserContext()
  const containerStyle = useSpring({
    top: !isOpen ? '100vh' : '0vh',
    bottom: !isOpen ? '-100vh' : '0vh',
  })

  const onSignOut = async () => {
    message.info("Signing out...")
    try {
      await signOut()
      setIsOpen(false)
      message.success("Signed out successfully!")
    } catch(e) {
      console.error(e)
      message.error("Sign out failed!")
    }
  }
  
  return (
    <>
      <OverlayContainer style={containerStyle}>
        <OverlayTopContentContainer>
          <TitleRow>
            <Title style={{ margin: 0 }}>
              Settings
            </Title>
            
            <a onClick={() => setIsOpen(false)} style={{ fontSize: 20 }}>
              <CloseCircleOutlined/>
            </a>
            
          </TitleRow>
          <Divider/>

          <Title level={3}>
            Account
          </Title>
          <TitleRow>
            <Title level={5}>
              Email
            </Title>
            <Text>
              { user?.email || 'n/a' }
            </Text>
          </TitleRow>

          <TitleRow>
            <Title level={5}>
              Email Verification
            </Title>
            {
              user?.emailVerified ? <Text style={{ color: medGreen(1) }}>VERIFIED</Text> : <Button size='small'>Resend</Button>
            }        
          </TitleRow>

          <TitleRow>
            <Title level={5}>
              Sign Out
            </Title>
            <Button 
              danger 
              onClick={onSignOut}
              type='primary' 
              size='small'>
              Sign Out
            </Button>
          </TitleRow>
        </OverlayTopContentContainer>
        <OverlayBottomContentContainer>
          <Text style={{ color: primaryColor(1) }}>{`Shorthand v${VERSION_STRING} ${process.env['NODE_ENV'] || '[development]'}`}</Text>
        </OverlayBottomContentContainer>
      </OverlayContainer>
      {/* @ts-ignore */}
      <StyledSettingsWidget style={props?.style} onClick={() => setIsOpen(true)}>
        <SettingOutlined/>
      </StyledSettingsWidget>
    </>
   
  );
}

export default SettingsWidget;
