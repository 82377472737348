import { DOMAIN_COLLECTION_NAME } from "@shorthand/config";
import { deserializeSHDocument, getDocPathParts, getDocPathString, getSHValueAsVector, getSHValueDimensions, serializeSHValue, SHDataDocToMatrix, SHValue, SHValueDocument, SHValueMatrix, SHValueScalar } from "@shorthand/data";
import { tranposeMatrix } from "@shorthand/utils";
import * as _ from "lodash"
import * as moment from "moment";
import { firestore } from '@shorthand/data'
import {
  SHDomainClientSettings,
  DefaultSHDomainClientSettings,
  createValueListenerFactory,
  createDocListenerFactory,
  ShorthandListenerHandler,
  ErrorHandler,
  ShorthandValueHandler
} from '@shorthand/get'
import { doc, onSnapshot, collection, addDoc, FirestoreDataConverter, WithFieldValue, DocumentData, QueryDocumentSnapshot, SnapshotOptions, DocumentReference, getDoc } from "firebase/firestore";
import { DomainKeysObject } from "@shorthand/domain";

export const FUNCTIONS_SUBCOLLECTION_NAME = 'function'
export const FUNCTION_CALL_INSTANCE_SUBCOLLECTION_NAME = 'functioncall'

export type FunctionCallDocPathArgs = {
  domainID: string,
  functionCallID: string
  functionCallCollectionName?: string,
}

export type FunctionDocPathArgs = {
  domainID: string,
  functionID: string,
  functionCollectionName?: string
}

export type FunctionCallCollectionPathArgs = {
  domainID: string,
  functionCallSubcollectionName?: string
}

export type FunctionCollectionPathArgs = {
  domainID: string,
  functionCollectionName?: string
}

export const getFunctionCallDocPathParts = ({
  domainID,
  functionCallID,
  functionCallCollectionName=FUNCTION_CALL_INSTANCE_SUBCOLLECTION_NAME,
}: FunctionCallDocPathArgs) => getDocPathParts({
  domainID: domainID,
  valueID: functionCallID,
  valueCollection: functionCallCollectionName,
})

export const getFunctionDocPathParts = ({
  domainID,
  functionID,
  functionCollectionName=FUNCTIONS_SUBCOLLECTION_NAME,
}: FunctionDocPathArgs) => getDocPathParts({
  domainID: domainID,
  valueID: functionID,
  valueCollection: functionCollectionName,
})

export const getFunctionDocPathString = ({
  domainID,
  functionID,
  functionCollectionName=FUNCTIONS_SUBCOLLECTION_NAME,
}: FunctionDocPathArgs) => getDocPathString({
  domainID: domainID,
  valueID: functionID,
  valueCollection: functionCollectionName
})

export const getFunctionCallDocPathString = ({
  domainID,
  functionCallID,
  functionCallCollectionName=FUNCTION_CALL_INSTANCE_SUBCOLLECTION_NAME,
}: FunctionCallDocPathArgs) => getDocPathString({
  domainID: domainID,
  valueID: functionCallID,
  valueCollection: functionCallCollectionName,
})

export const getFunctionCallCollectionPathParts = ({
  domainID,
  functionCallSubcollectionName=FUNCTION_CALL_INSTANCE_SUBCOLLECTION_NAME,
}: FunctionCallCollectionPathArgs) => [
  DOMAIN_COLLECTION_NAME,
  domainID,
  functionCallSubcollectionName,
]

export const getFunctionCallCollectionPathString = (args: FunctionCallCollectionPathArgs) => getFunctionCallCollectionPathParts(args).join('/')

export const getFunctionCollectionPathParts = ({
  domainID,
  functionCollectionName=FUNCTIONS_SUBCOLLECTION_NAME,
}: FunctionCollectionPathArgs) => [
  DOMAIN_COLLECTION_NAME,
  domainID,
  functionCollectionName,
]

export const getFunctionCollectionPathString = (args: FunctionCallCollectionPathArgs) => getFunctionCollectionPathParts(args).join('/')

export type SHFunctionType = 'POST' | 'GET' | 'GQLQUERY' | 'GQLMUTATION' | 'SQL' | 'VANILLA-TS' | 'SHLLMFunction'

export type SHFunctionArgType = 'STRING' | 'NUMBER' | 'ARRAY' | 'MATRIX' | 'BOOLEAN'

export type SHFunctionArgConfig = {
  name: string,
  dataType: SHFunctionArgType,
  required?: boolean,
  defaultValue?: SHValue | any
}

export type SHFunctionDoc = SHWebhookRESTFunction | SHWebhookLLMFunction | SHFunctionDocBase

export type ShorthandLLMFunctionExample = {
  id: string;
  inputSHValue: SHValue;
  outputSHValue: SHValue;
};

export type ShorthandLLMFunctionObj = {
  ref?: DocumentReference<DocumentData>
} & SHWebhookLLMFunction

export interface SHWebhookLLMFunction extends SHFunctionDocBase {
  type: 'SHLLMFunction'
  prompt: string,
  examples?: ShorthandLLMFunctionExample[];
}

export interface SHWebhookRESTFunction extends SHFunctionDocBase {
  type: 'POST' | 'GET'
  endpoint?: string,
  auth?: SHWebhookRESTFunctionAuth
}

export type SHWebhookRESTFunctionAuth = SHWebhookRESTFunctionAuthBasic | SHWebhookRESTFunctionAuthNone

export type SHWebhookRESTFunctionAuthBasic = {
  type: 'BASIC',
  token: string
}

export type SHWebhookRESTFunctionAuthNone = {
  type: 'NONE',
}

export interface SHFunctionDocBase {
  id: string
  author?: {
    email?: string,
    uid?: string,
  }
  createdTS: number
  updatedTS: number
  description?: string
  type: SHFunctionType
  endpoint?: string,
  args?: SHFunctionArgConfig[]
}


export type SHFunctionCallDoc = {
  id: string
  functionID: string
  executor?: {
    email?: string,
    uid?: string,
  },
  sequenceID?: string // in polling situation
  clientInitiatedTS: number
  callStartTS?: number
  callEndTS?: number
  error?: SHResultError,
  warning?: SHResultWarning
  value?: SHValue
} & SHFunctionArgsPayload

export type SHResultError = {
  name?: string
  message?: string,
  code?: string | number,
}

export type SHResultWarning = {
  name?: string
  message?: string,
  code?: string | number,
}

export type SHFunctionArgsExtracted = {
  argv?: any[]
  kwArgs?: {
    [argID: string]: any
  }
}

export type SHFunctionArgsPayload = {
  argsRaw?: string
  argSerialization?: 'NONE' | 'JSON' | 'REMOTEBLOB',
} & SHFunctionArgsExtracted

export const enrichRawArgs = <T=any>(argsRaw: T[][]) => {
  const dimensions = getSHValueDimensions(argsRaw as any[][])
  const isColumnVector = (dimensions.Y === 1) && (dimensions.X > 1)
  const argv = isColumnVector ? argsRaw?.map(v => v[0]) : argsRaw[0]


  const kwArgsL = (dimensions.Y > 1) ? _.fromPairs(
    argsRaw.map(([key, ...vect], idx) => [(key && !_.isEmpty(key)) ? key : `row${idx}`, vect])
  ) : { }

  const tranposed = tranposeMatrix(argsRaw)
  const kwArgsW = (dimensions.X > 1) ? _.fromPairs(
    tranposed.map(([key, ...vect], idx) => [(key && !_.isEmpty(key)) ? key : `col${idx}`, vect])
  ) : { }

  return ({
    argv,
    kwArgs: {
      ...kwArgsW,
      ...kwArgsL
    }
  })
}

const rootURL = `https://call.shorthand.ai`
export const getFunctionCallURL = ({ domainID }: { domainID: string }) => ({ functionCallID }: { functionCallID: string }) => {
  return `${rootURL}/${domainID}/${functionCallID}`
}

export const snapshotToCallDoc = (snapshot: QueryDocumentSnapshot | any, options?: SnapshotOptions): SHFunctionCallDoc => {
  const data = options ? snapshot.data(options) as any : snapshot.data()
  return {
    sequenceID: snapshot.id, // overwritten if exists on doc
    ...data,
    id: snapshot.id,
    // TODO: support other arg serialization besides JSON
    argv: data.argv ? JSON.parse(data.argv) : undefined,
    kwArgs: data.kwArgs ? JSON.parse(data.kwArgs) : undefined
  };
}

export const SHFunctionCallConverter: FirestoreDataConverter<SHFunctionCallDoc> = {
  toFirestore(doc: WithFieldValue<SHFunctionCallDoc>): DocumentData {
    return ({
      ...doc,
      // TODO: support other arg serialization besides JSON
      argv: doc.argv ? JSON.stringify(doc.argv) : null,
      kwArgs: doc.kwArgs ? JSON.stringify(doc.kwArgs) : null
    })
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SHFunctionCallDoc {
    return snapshotToCallDoc(snapshot, options)
  },
};

export const SHFunctionConverter: FirestoreDataConverter<SHFunctionDoc> = {
  toFirestore(doc: WithFieldValue<SHFunctionDoc>): DocumentData {
    return doc;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SHFunctionDoc {
    const data = snapshot.data(options) as SHFunctionDoc
    return {
      ...data,
      id: snapshot.id,
    };
  },
};

export const ShorthandCallHandlerFactory = (docRef: DocumentReference<DocumentData>) => <T extends SHFunctionCallDoc>(onValue: ShorthandValueHandler, onFinish: ShorthandListenerHandler<T>, onError: ErrorHandler) => {
  const superHandler = (doc: T | undefined) => {
    if (!doc) {
      return 
    }

    if (doc.value) {
      onValue(SHDataDocToMatrix(doc))
    }

    if (doc.error) {
      onError(doc.error)
    }

    if (doc.callEndTS) {
      onFinish(doc)
    }
  }
  
  return createDocListenerFactory(docRef)(superHandler, onError)
}

export const ShorthandCall = ({ defaultDomainID }: SHDomainClientSettings=DefaultSHDomainClientSettings) => ({ functionID }: { functionID: string }) => {

  const collectionPathString = getFunctionCallCollectionPathString({
    domainID: defaultDomainID
  })

  const ref = collection(firestore, collectionPathString).withConverter(SHFunctionCallConverter)

  const functionDocRef = doc(firestore, getFunctionDocPathString({
    domainID: defaultDomainID,
    functionID,
  })).withConverter(SHFunctionConverter)

  const createNew = async ({ kwArgs, argv}: SHFunctionArgsPayload) => {

    try {
      const data: Partial<SHFunctionCallDoc> = { 
        kwArgs,
        // argv,
        argSerialization: 'JSON',
        functionID,
        clientInitiatedTS: moment.now(),
      }

      const doc = await addDoc(ref, data)
      
      return ({
        doc, 
        // url: getFunctionCallURL({ domainID: defaultDomainID })({ functionCallID: doc.id }),
        // message: 'SUCCESS'
      })
    } catch(e) {
      return ({
        doc: undefined, 
        url: undefined,
        message: `[createNew failed] ${JSON.stringify(e)}`
      })
    }
  }

  const callH = async (argIDVector: string[], argValueVector: any[][]) => {
    const functionDoc = await (await getDoc(functionDocRef)).data()
    const extracted = extractArgsByArgIDHeader(functionDoc?.args || [])(argIDVector, ...argValueVector)
    return createNewAndListenToResult(extracted)
  }

  const callV = async (argValueVector: any[][][]) => {
    const functionDoc = await (await getDoc(functionDocRef)).data()
    const extracted = extractArgsOrdered(functionDoc?.args || [])(...argValueVector)
    return createNewAndListenToResult(extracted)
  }

  const callP = async (args: any[][][]) => {
    const functionDoc = await (await getDoc(functionDocRef)).data()
    const extracted = extractArgsByKWPairs(functionDoc?.args || [])(...args)
    return createNewAndListenToResult(extracted)
  }

  const createNewAndListenToResult = async (extracted: ReturnType<ReturnType<typeof extractArgsByKWPairs>>) => {
    const res = await createNew({ ...extracted })
    const docRef = res.doc?.path ? doc(firestore, res.doc?.path || '') : undefined
    const createResultListener = docRef && ShorthandCallHandlerFactory(docRef)
    return ({
      createResultListener,
      ...res,
      ...extracted
    })
  }
  
  return ({
    callH,
    callV,
    callP,

    ref,
    collectionPathString,
    createNew,
    createNewAndListenToResult,
    getFunctionDocRef: () => doc(
      firestore, 
      getFunctionDocPathString({
        domainID: defaultDomainID,
        functionID
      })
    )
  })
}
  

export type CallContextDoc = {
  keys?: DomainKeysObject
  secrets?: {
    [secretID: string]: string
  }
  orgID?: string
  domainID?: string,
  callerUserUID?: string
  callID: string
}


export const extractNumberFromExcelArg = (value: SHValueScalar[][]) => {
  const possibleNum = _.first(_.flattenDeep(value))
  switch (typeof(possibleNum)) {
    case 'number': 
      return possibleNum

    case 'string':  
      const out = parseFloat(possibleNum)
      return _.isFinite(out) ? out : null

    default:
      return null
  }
}


export const extractArgValue = (argConfigs: SHFunctionArgConfig) => (mat: SHValueScalar[][]) => {
  switch (argConfigs.dataType) {

    case 'NUMBER':
      return extractNumberFromExcelArg(mat)

    case 'STRING':
      const str = _.first(_.flattenDeep(mat))
      return str ? `${str}` : null

    case 'ARRAY':
      return getSHValueAsVector(mat)

    case 'MATRIX':
      return serializeSHValue(mat as SHValueMatrix)

    default:
      return _.first(_.flattenDeep(mat))
  }
}


// for a sequence of args. Assume the ordered args
export const extractArgsOrdered = (argConfigs: SHFunctionArgConfig[]) => (...args: SHValueScalar[][][])  => {
  const warnings: SHResultWarning[] = []
  const errors: SHResultError[] = []
  
  const argsLabeled = argConfigs.map((argConfig, i) => {
    if (i > args.length) {
      if (argConfig.required) {
        errors.push({
          name: `ARGUMENT MISSING: ${argConfig.name}`,
          code: 'ARG_REQUIRED_MISSING',
          message: `Argument [${argConfig.name}] is missing but is required`
        })
      }
      return [
        argConfig.name,
        extractArgValue(argConfig)([[]])
      ] as [string, SHValue]
    }
    return [
      argConfig.name,
      extractArgValue(argConfig)(args[i])
    ] as [string, SHValue]
  })

  const argv = argsLabeled.map(([k, v]) => v)
  const kwArgs = _.fromPairs(argsLabeled)

  return ({
    argv,
    kwArgs,
    errors,
    warnings,
  })
}

// take arguments (kw1: string, val1: any[][], kw2: string, val2: any[][], ...)
export const extractArgsByArgIDHeader = (argConfigs: SHFunctionArgConfig[]) => (argIDs: string[], ...args: SHValueScalar[][])  => {
  const warnings: SHResultWarning[] = []
  const errors: SHResultError[] = []
  
  const argsLabeled = argConfigs.map((argConfig, i) => {
    if (i > args.length) {
      if (argConfig.required) {
        errors.push({
          name: `ARGUMENT MISSING: ${argConfig.name}`,
          code: 'ARG_REQUIRED_MISSING',
          message: `Argument [${argConfig.name}] is missing but is required`
        })
      }
      return [
        argConfig.name,
        extractArgValue(argConfig)([[]])
      ] as [string, SHValue]
    }

    const argIndex = argIDs.findIndex((s) => s === argConfig.name)
    if (argIndex < 0) {
      return [
        argConfig.name,
        extractArgValue(argConfig)([[]])
      ] as [string, SHValue]
    }
    return [
      argConfig.name,
      extractArgValue(argConfig)([args?.[argIndex]])
    ] as [string, SHValue]
  })

  const argv = argsLabeled.map(([k, v]) => v)
  const kwArgs = _.fromPairs(argsLabeled)

  return ({
    argv,
    kwArgs,
    errors,
    warnings,
  })
}

// take arguments (kw1: string, val1: any[][], kw2: string, val2: any[][], ...)
export const extractArgsByKWPairs = (argConfigs: SHFunctionArgConfig[]) => (...args: SHValueScalar[][][])  => {
  const warnings: SHResultWarning[] = []
  const errors: SHResultError[] = []
  
  const argsLabeled = argConfigs.map((argConfig, i) => {
    if (i > args.length) {
      if (argConfig.required) {
        errors.push({
          name: `ARGUMENT MISSING: ${argConfig.name}`,
          code: 'ARG_REQUIRED_MISSING',
          message: `Argument [${argConfig.name}] is missing but is required`
        })
      }
      return [
        argConfig.name,
        extractArgValue(argConfig)([[]])
      ] as [string, SHValue]
    }
    return [
      argConfig.name,
      extractArgValue(argConfig)(args[i])
    ] as [string, SHValue]
  })

  const argv = argsLabeled.map(([k, v]) => v)
  const kwArgs = _.fromPairs(argsLabeled)

  return ({
    argv,
    kwArgs,
    errors,
    warnings,
  })
}
