import { CheckCircleOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { DOMAIN_COLLECTION_NAME } from '@shorthand/config';
import { CopyableLink } from '@shorthand/atoms';
import { USER_DOC_COLLECTION_NAME } from '@shorthand/domain';
import { firestore } from '@shorthand/firebase';
import { useAuthUserContext } from '@shorthand/hooks';
import { dangerColor, primaryColor } from '@shorthand/theme';
import { Button, Divider, notification, Skeleton, Space, Tag, Typography } from 'antd';
import { addDoc, collection, deleteDoc, doc, query, where } from 'firebase/firestore';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { useCollection, useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import moment from 'moment';
import confirm from 'antd/lib/modal/confirm';

const { Title, Text } = Typography
/* eslint-disable-next-line */
export interface ContainersProps {}

const StyledContainers = styled.div`
  color: pink;
`;

export function Containers(props: ContainersProps) {
  return (
    <StyledContainers>
      <h1>Welcome to Containers!</h1>
    </StyledContainers>
  );
}

export default Containers;

export const ShorthandAPIDomainsManager = () => {
  const { uid } = useAuthUserContext()
  const userDocRef = doc(firestore, `${USER_DOC_COLLECTION_NAME}/${uid || 'na'}`)

  const [ data, ...userDocQuery ] = useDocumentData(
    userDocRef
  )

  // const [ domains, loading, error ] = useCollectionData(
  //   query(collection(firestore, DOMAIN_COLLECTION_NAME), where(
  //     `users.${uid || 'na'}.admin`, '==', true
  //   ))
  // )

  const domainIDs = data ? [ data['primaryDomainID'] ] : []
  
  return (
    <div>
      {/* {
        loading ?  (
          <Skeleton active />
        ) : null
      }
      {
        error ?  (
          <p style={{ color: dangerColor(1) }}>
            { error.name } - { error?.code}: { error.message }
          </p>
        ) : null
      } */}
      {

      }

      {
        domainIDs?.map(domainID => (
          <ShorthandAPITokensManager
            domainID={domainID}
            key={domainID}
            />
        ))
      }
    
    </div>
  )
}

const NewTokenPaneContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  align-items: stretch;
`

export const NewTokenPane = ({ 
  newToken
}: {
  newToken: string
}) => {
  return(
    <NewTokenPaneContainer>
      <Title level={4}>
        New API Key
      </Title>
      <Text>
        Your new API token has been generated! Please copy and store securely:
      </Text>
      <br/>
      <CopyableLink
        url={newToken}
        urlShort={newToken}
        note="Copy token to clipboard"
        alert='Token copied to clipboard!'
        />
    </NewTokenPaneContainer>
  )
}

const TokenRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`


export const TokenRow = ({ 
  tokenDoc,
  onDelete
}: {
  tokenDoc: any,
  onDelete: () => any
}) => {
  return(
    <TokenRowContainer>
      <Space>
        <Text>
          { tokenDoc?.id.slice(0, 5) }...
        </Text>
        <Text style={{ fontSize: 'small', opacity: 0.5 }}>
          { tokenDoc?.createdTS ? moment.unix(tokenDoc?.createdTS / 1000).format('MM/DD/YYYY') : '--' } by { tokenDoc?.creator?.email || tokenDoc?.creator?.uid || 'SYSTEM' }
        </Text>
      </Space>

      <Space>
        <Button type='link' danger onClick={() => {
          confirm({
            title: `Delete this token (${tokenDoc?.id.slice(0, 5)}...)?`,
            onOk: async () => {
              await onDelete()
              notification.success({
                message: `Token ${tokenDoc?.id.slice(0, 5)}... deleted`
              })
            },
            okButtonProps: {
              danger: true,
              title: 'Delete',
              children: (
                <span>
                  Delete
                </span>
              )
            }
          })
        }}>
          <DeleteOutlined/>
        </Button>
      </Space>
      
    </TokenRowContainer>
  )
}


const ShorthandAPITokensManagerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  align-items: stretch;
`
export const ShorthandAPITokensManager = ({
  domainID
} : { 
  domainID: string
}) => {
  const { uid, user } = useAuthUserContext()
  const collectionPath = `${DOMAIN_COLLECTION_NAME}/${domainID}/token`
  const collectionRef = collection(firestore, collectionPath)
  const [ newToken, setNewToken ] = useState<string>()

  const [ tokens, loading, error ] = useCollection(
    collectionRef
  )

  const onCreateNewToken = async () => {
    notification.info({
      message: "Generating new API Token...",
      icon: <LoadingOutlined spin/>
    })
    const res = await addDoc(collectionRef, {
      domainID,
      creator: {
        uid,
        email: user?.email || null
      },
      createdTS: moment.now()
    })
    const newTokenID = `sh-${res.id}`
    notification.success({
      message: "New token generated successfully!",
      icon: <CheckCircleOutlined/>
    })
    setNewToken(newTokenID)
    return newTokenID
  }

  return (
    <ShorthandAPITokensManagerContainer>
      <Divider/>
      <Title level={4}>
        { domainID }
      </Title>
      <Space>
        <Title level={5}>
          Active API Keys

          <span style={{ fontSize: 'small', marginLeft: 10, opacity: 0.5, color: primaryColor(1) }}>
            { domainID }
          </span>
        </Title>
        

      </Space>
      
      
      {
        loading ?  (
          <Skeleton active />
        ) : null
      }
      {
        error ?  (
          <p style={{ color: dangerColor(1) }}>
            { error.name } - { error?.code}: { error.message }
          </p>
        ) : null
      }
      {
        tokens?.docs.map((t, index) => (
          <TokenRow 
            onDelete={() => deleteDoc(t.ref)}
            key={t.id || index} 
            tokenDoc={{
              ...t.data(),
              id: `sh-${t.id}`
            }}/>
        ))
      }
      <Button 
        style={{ marginTop: 10 }}
        block
        type='primary'
        onClick={onCreateNewToken}
        >
        Generate New API Key
      </Button>
      { newToken ? (
        <>
          <Divider/>
          <NewTokenPane newToken={newToken}/>
        </>
      ) : null }
      
    </ShorthandAPITokensManagerContainer>
  )
}