import { StrictMode, useMemo } from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  useParams
} from "react-router-dom";
import { GetTokens } from './containers/GetTokens/GetTokens';
import { AuthUserContextProvider, useSHFunction } from "@shorthand/hooks";
import { CustomFunctionEditor, CustomFunctionEditorApp, Login, LoginOverlay, SHFunctionEditor, SHValueHooksEditorApp } from '@shorthand/molecules'
import { LLMFunctionEditorApp, LLMFunctionsIndex } from '@shorthand/containers';
import { Alert, Skeleton } from 'antd';
import { Theme } from '@radix-ui/themes';
// import 'libs/atoms/src/lib/aria/index.css'
import '@radix-ui/themes/styles.css';
import './styles.css'

const LLMFunctionEditorAppRouted = () => {
  const params = useParams()
  
  return (
    <LLMFunctionEditorApp id={params['functionID']}/>
  )
}

const SHValueHooksEditorAppRouted = () => {
  const params = useParams()
  
  return (
    <SHValueHooksEditorApp id={params['functionID']}/>
  )
}


const FunctionEditorAppRouted = () => {
  const params = useParams()
  const functionID = params['functionID']
  const { data, loading, error, } = useSHFunction(functionID)

  const functionApp = useMemo(() => {
    switch (data.type) {
      case 'SHLLMFunction': 
        return <LLMFunctionEditorApp id={functionID}/>

      case 'VANILLA-TS':
        return (
          <Alert
            style={{ margin: '1rem' }}
           message={'Built in function'}
           description={'This is a build-in function that cannot be edited. If you require this namespace, please contact an administrator or shorthand.ai support'}
           type="error"
           showIcon
         />
       )

      default:
        return (
          <Theme accentColor="tomato" grayColor="mauve" appearance="dark">
            <CustomFunctionEditorApp functionID={functionID}/>
          </Theme>
          
        )
    }

  }, [ data.type ])
  
  return (
    <> 
      { error && (
         <Alert
          message={error?.name}
          description={error?.message}
          type="error"
          showIcon
        />
      )}
      {
        loading ? (
          <Skeleton/>
        ) : (
          functionApp
        )
      }
    </>
   
  )
}


const router = createBrowserRouter([
  {
    path: "/console/tokens",
    element: <GetTokens/>,
  },
  {
    path: "/console/tokens/*",
    element: <GetTokens/>,
  },
  // {
  //   path: "/dev/debug/shvalue",
  //   element: <SHValueDebugger/>,
  // },
  {
    path: "/console/hooks/:functionID",
    element: <SHValueHooksEditorAppRouted/>,
  },
  {
    path: "/console/functions",
    element: <LLMFunctionsIndex/>,
  },
  // {
  //   path: "/console/functions/:functionID",
  //   element: <FunctionEditorAppRouted/>,
  // },
  {
    path: "/console/functions/:functionID",
    element: <LLMFunctionEditorAppRouted/>,
  },
  {
    path: "/",
    element: <h1>404</h1>
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <AuthUserContextProvider>
      <LoginOverlay>
        {/* <Theme accentColor="orange" grayColor="sand" radius="large" scaling="95%"> */}

        <RouterProvider router={router}/>
      </LoginOverlay>
    </AuthUserContextProvider>
  </StrictMode>
);