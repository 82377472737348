import _ from 'lodash';
import hash from 'object-hash'
import * as seedrandom from 'seedrandom'
import moment from 'moment';

export function utils(): string {
  return 'utils';
}

export const getMatrixDimensions = <T>(arr: T[][]) => {
  const rowCount = arr?.length;
  const colCount = arr?.length ? arr[0]?.length : undefined
  return ({
    rowCount,
    colCount,
  })
}

export const createEmptyArray = <T>(n: number, filler: () => T) => new Array<T>(n).fill(filler())

/* 
deprecated; used in `+= getNorthwestSummand(i, j) + getNorthSummand(i, j) + getWestSummand(j, j)` solution (same complexity but had duplicative check for i === 0 or j === 0)
*/
export const createEmptyMatrix = <T>(m: number, n: number, fill: T) => createEmptyArray(
  m, 
  () => createEmptyArray(n, () => fill)
)

/* 
deprecated; used in `+= getNorthwestSummand(i, j) + getNorthSummand(i, j) + getWestSummand(j, j)` solution (same complexity but had duplicative check for i === 0 or j === 0)
*/
export const dimensionalClone = (a: number[][]) => {
  if (!a.length) return []
  const out = createEmptyMatrix(a.length, a[0].length, null)
  return out
}

export const cloneMatrix = <T>(mat: T[][]) => {
  return mat.map((col) => [...col])
}

export const tranposeMatrix = <T>(mat: T[][]) => {
  return _.zip(...mat) as T[][]
}

export const isEqual = (a: any, b: any) => {
  return _.isEqual(a, b)
}

export const noop = (args?: any) => null as any;

export type AnyKeys = {
  [key: string]: any;
};

export const isSubmitKey = (key: string | number) => {
  return ['Enter', 'Return', 'enter', 'return'].findIndex(k => k === key) >= 0;
};

export const hashRand = (args: any) => {
  // @ts-ignore
  const str = hash({ args })
  // @ts-ignore
  const rng = seedrandom('str')
  return rng()
}

const MIN_REASONABLE_TIMESTAMP = moment().subtract(20, 'year').valueOf()
const MAX_REASONABLE_TIMESTAMP = moment().add(20, 'year').valueOf()

export const isLikelyTimestamp = (s: number | string) => {
  const str = `${s}`
  const n = parseInt(str)
  if (!_.isFinite(n)) return false
  if (n < MIN_REASONABLE_TIMESTAMP) return false
  if (n > MAX_REASONABLE_TIMESTAMP) return false
  return  true
}

export const delayedExecution = (delayMS: number) => <T=any>(f: () => Promise<T>) => {
  return new Promise<T>((resolve, reject) => {
    setTimeout(async () => {
      try {
        const out = await f()
        resolve(out)
      } catch(e) {
        console.error("delayedExecution failed")
        reject(e)
      }
    }, delayMS)
  })
}

export const anticipatedExecution = (target: moment.Moment, eagernesMS: number) => {
  const now = moment.now()
  const remaining = target.valueOf() - now
  const executionDelay = remaining - eagernesMS
  return delayedExecution(executionDelay)
}

export const manyAnticipatedExecution = <T=any>({
  ticks=[ 1000, 2000 ],
  target,
  f
} : {
  ticks?: number[],
  target: moment.Moment,
  f: () => Promise<T>
}) => {
  return Promise.all(ticks.map(t => anticipatedExecution(
    target, 
    t,
  )(f)))
}

export const roundN = (x: number=0, n: number=2) => Math.round((x || 0) * Math.pow(10, n)) / Math.pow(10, n);


export function validateEmail(email: string) {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function deg2rad(deg: number) {
  return deg * (Math.PI/180)
}
/* globals window */

export const getAbsoluteURL = (url: string, req = null) => {
  let host
  if (req) {
    // @ts-ignore
    host = req.headers.host
  } else {
    if (typeof window === 'undefined') {
      throw new Error(
        'The "req" parameter must be provided if on the server side.'
      )
    }
    host = window.location.host
  }
  const isLocalhost = host.indexOf('localhost') === 0
  const protocol = isLocalhost ? 'http' : 'https'
  return `${protocol}://${host}${url}`
}

export default getAbsoluteURL
type DictQueryInfo = { loading: boolean, error?: any }
type ArrQueryInfo = [boolean, any]
type QueryInfo = DictQueryInfo | ArrQueryInfo

export const reduceQueryInfo = (arr: QueryInfo[]) => {
  // @ts-ignore
  const error = arr.reduce((acc, a) => acc || ( a?.hasOwnProperty('error') ? a.error : a?.[0]), undefined as Error | undefined)
  // @ts-ignore
  const loading = arr.reduce((acc, a) => acc || ( a?.hasOwnProperty('loading') ? a.loading : a?.[0]), false)

  return ({
    error,
    loading
  })
}


export const parseEmails = (s: string): string[] => {
  const re = /[^< ]+(?=>)/g;
  const emails = s.match(re)?.map(function(email) {
    return email
  });
  return _.uniq(_.compact([...(emails || []), ...s.split(',')])).filter(validateEmail)
}

export const initialize2D = <S>(x: number, y: number, f: (i: number, j: number) => S): S[][] => {
  const arr2d = (new Array(x)).fill(null).map(
    () => (new Array(y)).fill(null)
  )
  return apply2D(arr2d, (v, i, j) => f(i, j))
} 

export const apply2D = <S, T>(arr2d: T[][], f: (input: T, i: number, j: number) => S): S[][] => {
  return arr2d.map((row, i) => row.map((col, j) => f(col, i, j)))
} 

export const isLastRowNonNull = <T=any>(mat: T[][]) => {
  if (!mat.length) {
    return true
  }

  const X = mat.length
  const Y = mat[mat.length - 1].length // length of last row
  for (var j = 0; j < Y; j++) {
    if (!_.isNil(mat[X - 1][j])) return true
  }

  return false
}


export const isLastColNonNull = <T=any>(mat: T[][]) => {
  if (!mat.length) {
    return true
  }

  const X = mat.length
  const Y = mat[mat.length - 1].length // length of last row
 
  for (var i = 0; i < X; i++) {
    if (!_.isNil(mat[i][Y - 1])) return true
  }

  return false
}

export const parseScalarToVal = (raw: string | null | undefined | number | boolean) => {  
  if (typeof raw === 'number') return raw
  if (typeof raw === 'boolean') return raw
  if (_.isNil(raw)) return null
  const lowercase = raw.toLowerCase()
  if (lowercase === 'true') return true
  if (lowercase === 'false') return false

  // @ts-ignore
  if (!isNaN(raw)) {
    try {
      const n = parseFloat(raw)
      if (_.isFinite(n)) return n
    } catch(e) {
      return raw
    }
  }
  
  return raw
}

export const compactifyMatrix = <T>(mat: T[][]) => {  
  const out = initialize2D(mat.length, mat[0].length, (i, j) => mat[i][j])
  console.log('compactifyMatrix', { out })
  while (!isLastRowNonNull(out)) {
    out.splice(out.length - 1, 1)
  }
  while (!isLastColNonNull(out)) {
    out.forEach(row => row.splice(row.length - 1, 1))
  }
  return out
}

export const dropNilKeys = <T extends Object>(obj: T)  => {
  return _.omitBy(obj, _.isNil) as T
}

export type ArrayElement<ArrayType extends readonly unknown[]> = 
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;