import styled from '@emotion/styled';
import { Space, Typography } from 'antd';
import { LogoSVG as Logo } from '@shorthand/atoms';
import brand from '@shorthand/theme/brand';
import { primaryColor } from '@shorthand/theme';

const { Text, Title } = Typography;

export function LogoStack({ href = '/', color }: any) {
  return (
    <a href={href} style={{ textDecoration: 'none' }}>
      <LogoSection>
        <Logo width={58} height={58} style={{ marginTop: 3, borderRadius: 12 }} />
        <Stack>
          <CompactTitle level={2} style={{ margin: 0, color }}>
            {brand.brand}
          </CompactTitle>
          <Tagline style={{ color }}>{brand.tagline}</Tagline>
        </Stack>
        <TagText level={4}>{/* BETA */}</TagText>
      </LogoSection>
    </a>
  );
}

export function AppLogoStack({ emph=undefined, target=undefined, href = '/console', appName }: any) {
  return (
    <a href={href} target={target} style={{ textDecoration: 'none' }}>
      <LogoSection>
        <Logo width={58} height={58} style={{ marginLeft: -10, marginRight: -5 }}/>
        {/* <Divider type="vertical" style={{ height: 40, marginTop: 7, marginLeft: 0 }} /> */}
        <Stack>
          <Emph>{emph || brand.site_domain}</Emph>
          <CompactTitle level={3} style={{ margin: 0 }} ellipsis={{ rows: 1 }}>
            { appName || brand.brand }
          </CompactTitle>
        </Stack>
       
      </LogoSection>
    </a>
  );
}

export function AppLogoStackDark({ emph=undefined, target=undefined, href = '/console', appName }: any) {
  return (
    <a href={href} target={target} style={{ textDecoration: 'none' }}>
      <LogoSection>
        <img 
          width={58} 
          height={58} 
          src={'/assets/brand/logo-shorthand.png'}
          style={{ marginLeft: -10, marginRight: -5 }}/>
        {/* <Divider type="vertical" style={{ height: 40, marginTop: 7, marginLeft: 0 }} /> */}
        <Stack>
          <Emph>{emph || brand.site_domain}</Emph>
          <CompactTitle level={3} style={{ margin: 0, color: 'whitesmoke' }} ellipsis={{ rows: 1 }}>
            { appName || brand.brand }
          </CompactTitle>
        </Stack>
       
      </LogoSection>
    </a>
  );
}

const Emph = styled(Text)`
  color: ${primaryColor(1)};
  font-weight: bold;
`

const LogoSection = styled(Space)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 324px;
  border-radius: 15px;
`;

const Tagline = styled(Text)`
  margin-bottom: 8px;
  margin-top: 0px;
`;

const TagText = styled(Title)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompactTitle = styled(Title)`
  margin-top: 0px;
  margin-bottom: 0px;
`;
