import { SHValue } from '@shorthandai/core';
import { useCollectionData, useDocument, useDocumentData } from 'react-firebase-hooks/firestore';
import { DocumentData, DocumentReference, DocumentSnapshot, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, addDoc, collection, deleteDoc, doc, query, setDoc, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Button, Form, Modal, Typography, Space, Badge, message, Divider, notification, Alert, Tag } from 'antd';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash'
import { QueryInfoTail, auth, collapseInfos, firestore } from "@shorthand/firebase";
import styled from '@emotion/styled';
import { SHArrayEditor } from './SHArrayEditor';
import { useAuthUserContext } from '@shorthand/hooks';
import { DOMAIN_COLLECTION_NAME } from '@shorthand/config';
import { DeleteOutlined, EditOutlined, LoadingOutlined, SettingOutlined } from '@ant-design/icons';
import { appBackground, blue, dangerColor, medGreen, orange, primaryColor } from '@shorthand/theme';
import { dropNilKeys, initialize2D } from '@shorthand/utils';
import { deserializeSHValue, serializeSHValue } from '@shorthand/data';
import { AppLogoStack, CopyableCodeBlock, LogoStack } from '@shorthand/atoms';
import { ShorthandLLMFunctionObj, ShorthandLLMFunctionExample, FUNCTIONS_SUBCOLLECTION_NAME } from '@shorthand/call';
// import { FormulaBuilder } from '@shorthand/molecules';

const ShorthandLLMFunctionObjConverter: FirestoreDataConverter<ShorthandLLMFunctionObj> = {
  toFirestore(functionDoc: ShorthandLLMFunctionObj): DocumentData {
    return dropNilKeys({
      ...functionDoc,
      examples: functionDoc?.examples?.map(
        example => JSON.stringify(example)
      ),
    });
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ShorthandLLMFunctionObj {
    const data = snapshot.data(options);
    return {
      ...data,
      prompt: data['prompt'],
      type: data['type'],
      createdTS: data['createdTS'],
      updatedTS: data['updatedTS'],
      examples: (data['examples'] || []).map((ex: string) => JSON.parse(ex)) as ShorthandLLMFunctionExample[],
      id: snapshot.id,
      ref: snapshot.ref,
    };
  },
};

const shorthandLLMFunctionExampleDocConverter: FirestoreDataConverter<ShorthandLLMFunctionExample> = {
  toFirestore(functionDoc: ShorthandLLMFunctionExample): DocumentData {
    return {
      inputSHValue: serializeSHValue(functionDoc.inputSHValue),
      outputSHValue: serializeSHValue(functionDoc.outputSHValue)
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ShorthandLLMFunctionExample {
    const data = snapshot.data(options);
    return {
      inputSHValue: deserializeSHValue(data?.['inputSHValue']),
      outputSHValue: deserializeSHValue(data?.['outputSHValue']),
      id: snapshot.id,
      // ref: snapshot.ref,
    };
  },
};

export function useShorthandLLMFunction(docId: string) {
  const { domainID } = useAuthUserContext()
  const docRef = doc(firestore, `${DOMAIN_COLLECTION_NAME}/${domainID}/${FUNCTIONS_SUBCOLLECTION_NAME}/${docId}`).withConverter(ShorthandLLMFunctionObjConverter);
  const examplesCollectionRef = collection(firestore, `${DOMAIN_COLLECTION_NAME}/${domainID}/${FUNCTIONS_SUBCOLLECTION_NAME}/${docId}/examples`).withConverter(shorthandLLMFunctionExampleDocConverter);
  const [functionDoc, ...functionQueryInfo] = useDocumentData(docRef);
  const [examplesQuery, ...exampleQueryInfo]  = useCollectionData(examplesCollectionRef);
  const { user } = useAuthUserContext()

  const onUpdate = (data: Partial<ShorthandLLMFunctionObj>) => { 
    return setDoc(docRef, data, { merge: true })
  }

  const examples = functionDoc?.examples || []
  const setExamples = (examples: ShorthandLLMFunctionExample[]) => onUpdate({ examples })
  
  const addExample = () => {
    setExamples([
      ...examples, 
      { 
        id: uuidv4(), 
        inputSHValue: initialize2D(3, 3, () => null), 
        outputSHValue: initialize2D(3, 3, () => null) 
      }
    ]);
  };

  const updateExample = (id: string, key: 'inputSHValue' | 'outputSHValue', value: SHValue) => {
    setExamples(examples.map(example => example.id === id ? { ...example, [key]: value } : example));
  };

  const initialize = () => {
    if (functionDoc?.createdTS) {
      console.error("Already initialized")
      message.error('Function creation failed: function already exists')
      return
    } 
    if (!user) {
      console.error("Not authorized")
      message.error('Function creation failed: user not authenticated')
      return 
    }
    return setDoc(docRef, {
      id: docId,
      createdTS: moment.now(),
      updatedTS: moment.now(),
      author: {
        uid: user?.uid,
        // @ts-ignore
        email: user.email, 
      },
      prompt: '',
      type: 'SHLLMFunction',
      args: [
        {
          name: 'input',
          dataType: 'MATRIX',
          required: true,
        }
      ]
    })
  };

  const deleteExample = (id: string) => {
    setExamples(examples.filter(example => example.id !== id));
  };

  const { error, loading } = collapseInfos([ 
    functionQueryInfo as any as QueryInfoTail, 
    exampleQueryInfo as any as QueryInfoTail
  ])

  return ({
    initialize,
    onUpdate,
    addExample,
    updateExample,
    deleteExample,
    error, 
    loading,
    functionDoc
  })
}

const { Text, Title } = Typography


export const LLMFunctionsIndex = () => {
  const [api, contextHolder] = notification.useNotification();

  const { domainID } = useAuthUserContext()
  const collectionPath = `${DOMAIN_COLLECTION_NAME}/${domainID}/${FUNCTIONS_SUBCOLLECTION_NAME}`
  const collectionRef = collection(firestore, collectionPath).withConverter(ShorthandLLMFunctionObjConverter)
  const queryRef = query(
    collection(firestore, collectionPath).withConverter(ShorthandLLMFunctionObjConverter
  )); // where('type', '==', 'SHLLMFunction')

  const [ docs, loading, error ] = useCollectionData(queryRef)

  return (
    <LLMFunctionsIndexContainer>
      <AppLogoStack
        appName='Functions'
        />
      <Divider/>
      {
        loading && <LoadingOutlined/>
      }
      {
        error && (
          <Alert
            message={error?.name}
            description={error?.message}
            type="error"
            showIcon
          />
        )
      }
      {
        docs?.map(doc => (
          <LLMFunctionEditorCard
            doc={doc}
            />
        ))
      }

    </LLMFunctionsIndexContainer>
  )
}


const getTagColor = (doc: any) => {
  switch(doc.type) {
    case 'SHLLMFunction':
      return orange(1)
    
    case 'POST':
      return dangerColor(1)

    case 'GET':
      return medGreen(1)

    default:
      return blue(1)
  }
}

const LLMFunctionEditorCard = ({ doc }: { doc: ShorthandLLMFunctionObj }) => { 
  const formula=`=SH.CALLV("${doc?.id || '...'}")`
  return (
    <LLMFunctionEditorCardContainer>
      <LLMFunctionsIndexContainerLeft>
        <Tag color={getTagColor(doc)} style={{ maxWidth: 150 }}>
          { doc?.type } 
        </Tag>
        <Title level={4} style={{ margin: 0 }}>
          { doc.id }
        </Title>
        <Text>
          { doc.description || doc.prompt }
        </Text>
      </LLMFunctionsIndexContainerLeft>
      <LLMFunctionsIndexContainerRight>
        {/* <CopyableCodeBlock formula={formula}/> */}
        <Space>
          { // @ts-ignore
            doc.type !== 'VANILLA-TS' && (
              <Button type='link' size='large' href={`/console/functions/${doc.id}`}>
                <SettingOutlined/>
              </Button>
            )
          }
        </Space>
      </LLMFunctionsIndexContainerRight>
      
    </LLMFunctionEditorCardContainer>
  )
}

const LLMFunctionEditorCardContainer = styled.div`
  border: 1px solid black;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`
const LLMFunctionsIndexContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 3;
`

const LLMFunctionsIndexContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 1;
`

const LLMFunctionsIndexContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const LLMFunctionEditorApp = ({ id }: { id: string }) => {
  const { uid } = useAuthUserContext()
  const docID =  _.isEmpty(id) ? `new-llmfunction-user:${uid}` : id
  const { functionDoc, initialize, loading, error, onUpdate, updateExample, addExample, deleteExample } = useShorthandLLMFunction(docID)
  const isLLMFunction = !!functionDoc?.type && (functionDoc.type === 'SHLLMFunction')
  const functionTypeMismatch = !!functionDoc?.type && (functionDoc?.type !== 'SHLLMFunction')

  return (
    <LLMFunctionEditorAppContainer>
      { loading && <LoadingOutlined/> }
      { error && <Text style={{ color: dangerColor(1) }}>{error.code}: {error.message}</Text>}
      { functionTypeMismatch && <Text style={{ color: dangerColor(1) }}>
        {docID} is not an LLM function, and the namespace is occupied.
      </Text> }
      { !functionDoc?.type && !loading && !error && (
        <>
          <Text>Function {docID} does not exist, but you can create it!</Text>
          <Button block type='primary' onClick={() => initialize()?.then(() => message.success(`${docID} function created!`))}>
            Create {docID}
          </Button>
        </>
      )}
      { !loading && !error && isLLMFunction && (
        <LLMFunctionEditor 
          onUpdate={onUpdate}
          addExample={addExample}
          updateExample={updateExample}
          deleteExample={deleteExample}
          doc={functionDoc}/> 
      )}
    </LLMFunctionEditorAppContainer>
  )
}

type MaybeLLMFunctionEditorProps<T=ShorthandLLMFunctionObj> = { 
  doc?: T, 
  onUpdate: (data: Partial<T>) => Promise<void>,
  addExample: ReturnType<typeof useShorthandLLMFunction>['addExample'],
  updateExample: ReturnType<typeof useShorthandLLMFunction>['updateExample'],
  deleteExample: ReturnType<typeof useShorthandLLMFunction>['deleteExample'],
}

export function LLMFunctionEditor({ 
  doc,
  onUpdate,
  addExample,
  updateExample,
  deleteExample
}: MaybeLLMFunctionEditorProps<ShorthandLLMFunctionObj>) {
  const examples = doc?.examples || []
  const prompt = doc?.prompt || ''
  const setPrompt = (s: string) => onUpdate({ prompt: s, description: s })

  const canAddExample = true

  const formula=`=SH.CALLV("${doc?.id || '...'}", )`
  return (
    <Container>
      <Form>
        <Title level={5} style={{ margin: 0 }}>Name</Title>
        <Title level={2} style={{ margin: 0 }}>
          { doc?.id || '--' }
        </Title>
        
        <Title level={5}>Prompt</Title>
        <Form.Item>
          <Input.TextArea 
            placeholder={'Describe what your function should do. \nExample: this function should transpose the input value.'}
            value={prompt} 
            onChange={e => setPrompt(e.target.value)} />
        </Form.Item>
        <Title level={5}>Usage</Title>
        <CopyableCodeBlock
          formula={formula}
        />
        {/* <FormulaBuilder

        /> */}

        <br/>
        <Title level={5}>Examples</Title>
        <CardsContainer>
        {examples.map((example, i) => (
          <Badge 
            count={0}
            key={example.id} 
            title='10'>

            <CardContainer key={example.id}>
              <Form.Item key={example.id}>

                <Row>
                  <Column>
                    <Title level={5}>Input</Title>
                    <SHArrayEditorContainer>
                      <SHArrayEditor
                        value={example.inputSHValue}
                        onChange={value => updateExample(example.id, 'inputSHValue', value)}
                      />
                    </SHArrayEditorContainer>
                   
                  </Column>

                  <Column>
                    <Title level={5}>Output</Title>
                    <SHArrayEditorContainer>
                      <SHArrayEditor
                        value={example.outputSHValue}
                        onChange={value => updateExample(example.id, 'outputSHValue', value)}
                      />
                    </SHArrayEditorContainer>
                    
                  </Column>
                </Row>
                
              </Form.Item>
              <CardBadge>
                <DeleteOutlined 
                  style={{ color: 'white' }}
                  onClick={() => deleteExample(example.id)} />
              </CardBadge>
            </CardContainer>
          </Badge>
        ))}
        </CardsContainer>
        <Button 
          block
          type='primary'
          disabled={!canAddExample}
          onClick={addExample}>
          Add Example
        </Button>
      </Form>
    </Container>
  );
}

const SHArrayEditorContainer = styled.div`
  display: flex; 
  flex-direction: column; 
  padding-top: 1px;
  /* border: 1px solid ${appBackground()}; */
  max-width: 100%;
  max-height: 400px;
  flex: 1;
  overflow: auto;
`

const Column = styled.div`
  display: flex; 
  flex-direction: column; 
  margin: 1rem;
  flex: 1;
  overflow: auto;
`

const Row = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: space-between;
  flex-wrap: wrap;
`


const Container = styled.div`
  display: flex; 
  flex-direction: column; 
  gap: 1rem;
`

const CardsContainer = styled.div`
  flex: 1;
  display: flex; 
  flex-direction: column; 
  max-width: 100%;
  height: 100%
`

const CardBadge = styled.a`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  background: ${dangerColor(1)};
  border: 2px solid ${dangerColor(0.8)};
  :hover {
    transform: scale(1.1);
  }
`

const CardContainer = styled.div`
  position: relative;
  background: white;
  /* height: 40px; */
  /* display: flex; */
  /* width: 100%; */
  flex: 1;
  max-width: 100%;
  margin-bottom: 1rem;
  height: 100%;
  
  border-radius: 4px;
`

const LLMFunctionEditorAppContainer = styled.div`
  background: ${appBackground()};
  display: flex; 
  flex-direction: column; 
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
`