import { animated } from 'react-spring';

export const LogoSVG = (props: any) => (
	<animated.svg
		width={props.size || props.width || 25}
		height={props.size || props.height || 25}
		viewBox="0 0 512 512"
		fill="none"
		{...props}>
		<svg
			width="512"
			height="512"
			viewBox="0 0 512 512"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="512" height="512" fill="white" />
			<g filter="url(#filter0_d)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M96 443.52L256 53.3333L416 443.52L400.853 458.667L256 394.667L111.147 458.667L96 443.52ZM347.306 388.266L255.999 165.76L164.693 388.266L238.719 355.626L255.999 347.946L273.279 355.626L347.306 388.266Z"
					fill="#FA7800"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d"
					x="92"
					y="53.3333"
					width="328"
					height="413.333"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	</animated.svg>
);

