// import { auth, firestore } from "@shorthand/firebase";
import { doc, DocumentData, FirestoreDataConverter, getDoc, getFirestore, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue } from "firebase/firestore";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import * as _ from "lodash"

export function domain(): string {
  return 'domain';
}

export type DomainDoc = {
  domainID: string
} & DomainDocData

export type DomainKeysObject = {
  [keyDomain: string]: {
    [keyName: string]: string
  }
}

export type DomainDocData = {
  keys?: DomainKeysObject
  orgID?: string,
  integrations?: {
    [k: string]: DomainWebhookIntegration,
  }
  integrationsNative?: {
    SHVALUE_WEBHOOK: DomainWebhookIntegration,
  }
}
export type DomainWebhookIntegration = {
  url: string,
  secret?: string
}

export let defaultDomainID: string;

type SHUserDoc = {
  id: string,
  primaryDomainID: string,
  displayName?: string,
  email?: string
}

export const SHUserConverter: FirestoreDataConverter<SHUserDoc> = {
  toFirestore(doc: WithFieldValue<SHUserDoc>): DocumentData {
    return doc
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SHUserDoc {
    const data = snapshot.data(options) as any
    return {
      ...data,
      id: snapshot.id,
    };
  },
};

export const USER_DOC_COLLECTION_NAME = 'user'

export const getDomainID = async () => {

  if (defaultDomainID && !_.isEmpty(defaultDomainID)) {
    return defaultDomainID
  }

  const uid = getAuth().currentUser?.uid 
  if (!uid) return 'demo'

  const userDocRef = doc(getFirestore(), `${USER_DOC_COLLECTION_NAME}/${uid}`)

  const useDoc = await (await getDoc(userDocRef.withConverter(SHUserConverter))).data()
  return useDoc?.primaryDomainID || 'demo'
}

export const onUserChanged = async (
  onChange: ({ uid, domainID, user }: { uid?: string, domainID?: string, user?: User | null}) => any
) => {
  return onAuthStateChanged(getAuth(), async (user) => {
    const uid = user?.uid
    let domainID: string = 'demo'
    if (uid && !_.isEmpty(uid)) {
      const userDocRef = doc(getFirestore(), `${USER_DOC_COLLECTION_NAME}/${uid}`)
      const userDoc = await (await getDoc(userDocRef.withConverter(SHUserConverter))).data()
      domainID = userDoc?.primaryDomainID || 'demo'
    }
    onChange({
      uid, 
      domainID, 
      user
    })
  })
}
