import { getMatrixDimensions } from "@shorthand/utils";
import objectHash, * as hash from 'object-hash'
import { doc, Firestore } from "firebase/firestore"; 
import { DOMAIN_COLLECTION_NAME, SH_VALUE_COLLECTION_NAME, SH_VALUE_TAGGED_VALUES_SUBCOLLECTION_NAME, SH_VALUE_HOOK_COLLECTION_NAME } from '@shorthand/config'
import * as _ from 'lodash'

import {
  SHValueScalar, 
  SHValueVector, 
  SHValueMatrix, 
  SHValue, 
  SHValueDataContent,
  SHValueData,
  SHValueDoc
} from '@shorthandai/core'

export {
  type SHValueScalar, 
  type SHValueVector, 
  type SHValueMatrix, 
  type SHValue, 
  type SHValueDataContent,
  type SHValueAuthor, 
  type SHValueAuthorType, 
  type SHValueData,
} from '@shorthandai/core'

export function data(): string {
  return 'data';
}

export type SHValuedDocument = {
  value?: SHValue
  vectorDimensions?: SHVectorDimensions
}

export type SHVectorDimensions = {
  X: number,
  Y: number,
}

export type SHValueDocument = {
  id: string,
  vectorType: SHValueDimensions,
  vectorDimensions: SHVectorDimensions,
} & SHValueData

export const trimToSHValueDoc = (doc: SHValueDocument): SHValueDoc => {
  return ({
    id: doc.id,
    value: doc.value,
    createdTS: doc?.createdTS,
    updatedTS: doc?.updatedTS,
    author: doc.author,
    format: doc?.format,
    name: doc?.name,
    description: doc?.description
  })
}

export type ShorthandGetDoc = SHValueDocument

export const fillMatrix = <T=any>(old: T[][], fills: T[][]) => {
  const { rowCount, colCount } = getMatrixDimensions(fills)
  return (
    old.map((row, i) => row.map((cell, j) => {
      if (rowCount && (i >= rowCount)) return cell;
      if (colCount && (j >= colCount)) return cell;
      return fills[i][j]
    }))
  )
}


export const getSHValueDimensionLength = (v: SHValue | undefined) => {
  const vType = typeof v
  if (['string', 'boolean', 'number'].includes(vType)) {
    return 0
  }
  if ((vType) === 'object') {
    const vect = (v as SHValueMatrix | SHValueVector)
    return vect?.length || 0
  }
  return 0
}

export const getSHValueDimensions = <T>(v: SHValue | undefined) => {
  const X = getSHValueDimensionLength(v)
  const Y = (X > 0) ? getSHValueDimensionLength((v as (SHValueVector | SHValueMatrix))[0]) : 0
  return ({ X, Y })
}

export const getSHValueDocDimensions = (d: SHValueDocument) => {
  if (d.vectorDimensions) {
    return d.vectorDimensions
  }
  return getSHValueDimensions(d.value)
}


export const flattenVector = (v: SHValueVector | SHValueScalar) => {
  const t = typeof(v)
  if (t === 'object') {
    const vect = v as SHValueVector
    const len = (vect)?.length
    if (len > 1) return vect
    if (!len) return v // if empty
    if (len === 1) return vect[0]
  }
  return v as SHValueScalar
}

export const reduceSHValueDimensions = (v: SHValue) => {
  const { X, Y } = getSHValueDimensions(v)
  // if ((m > 1) && (n > 1)) return v // cannot reduce dimensions
  if (X === 0) return v // m must be 0
  else if (X === 1) {
    if (Y === 1) {
      const vect = v as SHValueMatrix
      return vect[0][0]
    }

    if (Y === 0) {
      const vect = v as SHValueVector
      return vect[0]
    }

    if (Y> 1) {
      const vect = v as SHValueVector
      return vect[0] // is a row vector; unpack
    }
  } 
  // n > 1
  else {
    if (Y === 0) return v // already fully reduced 1-D vector
    if (Y === 1) return (v as SHValueMatrix).map((v_: SHValueVector) => v_[0]) // is a column vector; convert to row vector
    return v
  }
  return v
}



export const classifySHDimensions = ({ X, Y } : ReturnType<typeof getSHValueDimensions>) => {
  if ((X > 0) && (Y > 0)) {
    return 'MATRIX'
  }

  if ((X === 0) && (Y === 0)) {
    return 'SCALAR'
  }

  return 'VECTOR'
}

export const getSHValueDimensionType = (v: SHValue): SHValueDimensions => {
  return classifySHDimensions(getSHValueDimensions(v))
}

export const getSHValueToPreString = (v: SHValue) => {
  const { X, Y } = getSHValueDimensions(v)
  switch (classifySHDimensions({ X, Y })) {
    case 'MATRIX':
      return {
        prefix: `${X}x${Y}`,
        values: (v as SHValueMatrix)[0].join(', ')
      }

    case 'VECTOR':
      return {
        prefix: `${X}`,
        values: (v as SHValueVector).join(', ')
      }

    case 'SCALAR': 
      return {
        prefix: '',
        values: `${v}`
      }
  }
}

export type SHValueDimensions = 'MATRIX' | 'VECTOR' | 'SCALAR'

export type DataTopic = {
  name: string;
  domainID: string;
  tag?: string
  key?: {
    x?: number,
    y?: number,
  }
}

export const DataTopicPartsGetter = ({ defaultDomain }: { defaultDomain: string}) => (path: string): DataTopic => {
  const parts = path.split('/')

  if (parts.length === 1) {
    return {
      name: parts[0],
      domainID: defaultDomain,
      tag: undefined
    }
  }

  if (parts.length === 2) {
    return {
      name: parts[0],
      domainID: defaultDomain,
      tag: parts[1]
    }
  }

  if (parts.length === 3) {
    return {
      name: parts[1],
      domainID: parts[0],
      tag: parts[2]
    }
  }

  console.error(`Invalid value path: ${path}`)
  throw new Error(`Invalid value path: ${path}`)
}

// TODO: need to handle Firestore not allowing arrays of arrays
export const wrapArray = <T>(arr: T[]) => {
  return ({
    isSHArrayWrapper: true,
    arr
  })
}

export type WrappedArray<T> = ReturnType<typeof wrapArray<T>>

export const unwrapWrappedArray = <T>({ arr }: WrappedArray<T>) => {
  return arr
}

// call this after reading value to compute dimensions, or when reading to consume
export const attemptUnwrapWrappedArray = (data: any) => {
  if (typeof data === 'object') {
    if (data?.isSHArrayWrapper) {
      return unwrapWrappedArray(data) as SHValue
    }
  }
  return data as SHValue
}

// call this before setting
export const wrapSHValue = (data: SHValue) => {
  const dims = getSHValueDimensions(data)
  if (dims.X && dims.Y) {
    const vect = data as SHValueMatrix
    return vect.map((v: SHValueVector) => wrapArray(v))
  }
  return data
}

// call this before setting
export const serializeSHValue = (v: any) => {
  const dims = getSHValueDimensions(v)
  if (dims.X && dims.Y) {
    const blob = JSON.stringify(v)
    const sizeBytes = blob.length
    // limit to 768Kb 
    if (sizeBytes > (768000)) {
      console.warn("Large blob received; compressing")
      const hash = objectHash(v)
      return {
        isSHArrayWrapper: true,
        sizeBytes,
        hash,
        blob,
      }
    }
    return {
      isSHArrayWrapper: true,
      blob: blob,
      sizeBytes
    }
  }
  return v
}

export const getSHValueAsVector = (mat: any[][]) => {
  const dims = getSHValueDimensions(mat)
  if ((dims.X === 0) || (dims.Y === 0)) {
    return [] as SHValueVector
  }

  if (dims.X === 1) {
    return _.first(mat) as SHValueVector
  }

  if (dims.Y === 1) {
    return mat.map(v => _.first(v)) as SHValueVector
  }

  return _.first(mat) as SHValueVector
}
// wrapSHValue

// call this before setting
export const deserializeSHValue = (s: any) => {
  if (typeof s === 'object') {
    if (s?.['isSHArrayWrapper']) {
      return JSON.parse(s['blob']) as SHValue
    }
  }
  return s as SHValue
}

export const serializeSHDocument = (d: Partial<SHValueDocument>) => {
  const value = serializeSHValue(d?.value)
  return ({
    ...d,
    value,
  }) as SHValueDocument
}

export const deserializeSHDocument = <T extends SHValuedDocument>(d: T) => {
  const value = deserializeSHValue(d?.value)
  return ({
    ...d,
    value,
  }) as T
}
// attemptUnwrapWrappedArray

// @ts-ignore
export const hashValueChange = (a: SHValueDataContent): string => hash({ 
  v: a.value,
  // d: a.description,
  // n: a.name,
  // k: a.format
})

export type SHValueIndexer = {
  x?: number,
  y?: number,
}

export type TaggedTopicName = {
  valueID: string,
  tag?: string | number
}

export type SHValueAccessorAnnotations = {
  tag?: string | number,
  x?: number,
  y?: number,
}

export type FullPathDocSpec = {
  domainID: string,
  valueCollection?: string
  valueID: string,
  valueSubcollection?: string
  tag?: string | number
} & TaggedTopicName

export type FullPathCollectionSpec = {
  domainID: string,
  valueCollection?: string
  valueID?: string,
  valueSubcollection?: string
  tag?: string
}

export type FullPathDocHistoryCollectionSpec = {
  domainID: string,
  valueCollection?: string
  valueID?: string,
  valueSubcollection?: string
}


export const getDomainDocPathString = ({
  domainID,
}: { domainID: string }) => {
  return [
    DOMAIN_COLLECTION_NAME,
    domainID
  ].join('/')
}

export const getDocPathParts = ({
  domainID,
  valueCollection=SH_VALUE_COLLECTION_NAME,
  valueID,
  valueSubcollection=SH_VALUE_TAGGED_VALUES_SUBCOLLECTION_NAME,
  tag
}: FullPathDocSpec) => {
  return [
    DOMAIN_COLLECTION_NAME,
    domainID,
    valueCollection,
    valueID,
    ...((tag && !_.isEmpty(tag)) ? [
      valueSubcollection,
      `${tag}`
    ] : [])
  ]
}

export const getDocPathString = ({
  domainID,
  valueCollection=SH_VALUE_COLLECTION_NAME,
  valueID,
  valueSubcollection=SH_VALUE_TAGGED_VALUES_SUBCOLLECTION_NAME,
  tag
}: FullPathDocSpec) => {
  return getDocPathParts({
    domainID,
    valueCollection,
    valueID,
    valueSubcollection,
    tag
  }).join('/')
}

export const getCollectionPathParts = ({
  domainID,
  valueCollection=SH_VALUE_COLLECTION_NAME,
  valueID,
  valueSubcollection=SH_VALUE_TAGGED_VALUES_SUBCOLLECTION_NAME,
  tag
}: FullPathCollectionSpec) => {
  const hasValueID = (valueID && !_.isEmpty(valueID))
  const hasTag = (tag && !_.isEmpty(tag))
  const tagPart = (hasValueID && hasTag) ? [ valueSubcollection, valueID ] : []
  return [
    DOMAIN_COLLECTION_NAME,
    domainID,
    valueCollection,
    ...tagPart
  ]
}


export const getTopicHistoryCollectionPathParts = ({
  domainID,
  valueCollection=SH_VALUE_COLLECTION_NAME,
  valueID,
  valueSubcollection=SH_VALUE_TAGGED_VALUES_SUBCOLLECTION_NAME,
}: FullPathDocHistoryCollectionSpec) => {
  return [
    DOMAIN_COLLECTION_NAME,
    domainID,
    valueCollection,
    valueID,
    valueSubcollection
  ]
}

export const getTopicHistoryCollectionPathString = (args: FullPathDocHistoryCollectionSpec) => getTopicHistoryCollectionPathParts(args).join('/')

export const getCollectionPathString = ({
  domainID,
  valueCollection=SH_VALUE_COLLECTION_NAME,
  valueID,
  valueSubcollection=SH_VALUE_TAGGED_VALUES_SUBCOLLECTION_NAME,
  tag,
}: FullPathCollectionSpec) => {
  return getCollectionPathParts({
    domainID,
    valueCollection,
    valueID,
    valueSubcollection,
    tag,
  }).join('/')
}

export const getTopicDocRef = (db: Firestore) => (topic: DataTopic) => {
  return doc(db, getDocPathString({
    valueID: topic.name,
    tag: topic.tag,
    domainID: topic.domainID,
  }))
}

export const getDatumByIndex = (dataDoc: SHValueDocument) => (indexer?: SHValueIndexer | undefined) => {
  const dims = dataDoc?.vectorDimensions || getSHValueDimensions(dataDoc.value)
  const mat = dataDoc.value as SHValueMatrix
  const vect = dataDoc.value as SHValueVector
  switch (classifySHDimensions(dims)) {
    case 'MATRIX':
      const x: number = indexer?.x || 0
      const y: number = indexer?.y || 0
      return mat?.[x]?.[y]

    case 'VECTOR':
      return (indexer?.x) ? vect[indexer?.x] : _.first(vect)

    case 'SCALAR':
      return dataDoc.value
  }
}

export const toSHScalarString = (dataDoc: Partial<SHValueDocument>) => {
  if (dataDoc?.value === undefined ) {
    return '[ EMPTY ]'
  }
  if (dataDoc?.value === null ) {
    return '[ NULL ]'
  }
  return `${dataDoc?.value}`
}

export const valueBadgeShortString = (dataDoc: Partial<SHValueDocument>) => (indexer?: SHValueIndexer) => {
  const dims = dataDoc.vectorDimensions || getSHValueDimensions(dataDoc.value)
  const mat = dataDoc.value as SHValueMatrix
  const vect = dataDoc.value as SHValueVector
  const dimClass = classifySHDimensions(dims)
  if (!indexer) {
    switch (dimClass) {
      case 'MATRIX':
        return ({ str: `[${dims.X} x ${dims.Y}]`, dimClass })

      case 'VECTOR':
        return ({ str: `[${dims.X}]: ${(vect?.join && vect?.join(', ')) || '??'}`, dimClass })

      case 'SCALAR':
        return ({ str: toSHScalarString(dataDoc), dimClass })
    }
  }

  switch (dimClass) {
    case 'MATRIX':
      const x: number = indexer?.x || 0
      const y: number = indexer?.y || 0
      return ({ str: mat?.[x]?.[y] as SHValueScalar, dimClass })

    case 'VECTOR':
      return ({ str: ((indexer?.x) ? vect[indexer?.x] : _.first(vect)) as SHValueScalar, dimClass })

    case 'SCALAR':
      return ({ str: toSHScalarString(dataDoc), dimClass })
  }
}



export const SHDataDocToMatrix = <T extends SHValuedDocument>(dataDoc: T) => {
  const dims = dataDoc.vectorDimensions || getSHValueDimensions(dataDoc.value)
  const val = dataDoc.value as SHValueScalar
  const mat = dataDoc.value as SHValueMatrix
  const vect = dataDoc.value as SHValueVector
  const dimClass = classifySHDimensions(dims)
  switch (dimClass) {
    case 'MATRIX':
      return mat

    case 'VECTOR':
      return [ vect ]

    case 'SCALAR':
      return [[ val ]]
  }
}

export const unpackSHValueDoc = (doc: any) => {
  const data = doc?.data() || {}
  return ({
    ...data,
    value: deserializeSHValue(data?.['value']),
    id: doc?.id
  }) as SHValueDocument
}


type SHValueHookType = 'POST' | 'PUT'

export type SHValueHookDoc = {
  id: string
  type: SHValueHookType
  description: string,
  url: string
}