import { Home } from "../Home/Home"
// import 'antd/dist/antd.css';
import { Button, Divider, Space, Typography } from "antd";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { auth, firestore } from "@shorthand/firebase";
import { doc } from "firebase/firestore";
import { USER_DOC_COLLECTION_NAME } from "@shorthand/domain";
import { useAuthState } from "react-firebase-hooks/auth";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import styled from "@emotion/styled";
import { ShorthandAPIDomainsManager } from "@shorthand/containers";

const { Title, Text } = Typography

const Container = styled.div`
  padding: 2rem;
`

export const GetTokens = () => {
  const [ user, ...authState ] = useAuthState(auth);
  const userDocRef = doc(firestore, `${USER_DOC_COLLECTION_NAME}/${user?.uid || 'na'}`)
  const [ data, loading, error ] = useDocumentData(
    userDocRef
  )
  return (
    <>
      <Container>
        <Title level={2}>Generate API Key</Title>
        <Divider/>
        <Text>
          Generated API keys for usage with your team's SDKs below.
          <br/>
        </Text>


        <ShorthandAPIDomainsManager/>
        <Divider/>

        <Space>
          <Text style={{ marginRight: 50 }}>
            Signed in as {user?.email}
          </Text>
          <Button danger type='primary' onClick={() => signOut(auth)}>
            Logout
          </Button>
        </Space>
        
      </Container>
    </>
  )
}