export function config(): string {
  return 'config';
}

export const DOMAIN_COLLECTION_NAME = `domain`
export const DOMAIN_EMAIL_MAP = `email_to_domain`
export const SH_VALUE_COLLECTION_NAME = `shvalue`
export const SH_VALUE_TAGGED_VALUES_SUBCOLLECTION_NAME = `val`

export const OFFICEJS_NAMESPACE = 'SH'

export const SH_VALUE_HOOK_COLLECTION_NAME = `shvaluehooks`

export const USE_EXCELJS_API = false