import styled from "@emotion/styled"
import { gradients } from "@shorthand/theme"
import { CSSProperties, ReactNode } from "react"


export const SHBadge = ({ children, background, color, style }: { style?: CSSProperties, children: ReactNode, background?: string, color?: string }) => {
  return (
    <InteractionTextBadge 
      background={background} 
      style={style}
      color={color}>
      { children }
    </InteractionTextBadge>
  )
}

const InteractionTextBadge = styled.div<any>`
  display: flex;
  border-radius: 4px;
  padding: 5px 20px;
  opacity: 1;
  justify-content: center;
  color: ${ ({ color }) => color || 'black' };;
  align-items: center;
  flex-direction: row;
  background: ${ ({ background }) => background || gradients.lightgreen };
`