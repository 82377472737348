import { classifySHDimensions, getSHValueDimensions, SHValue, SHValueDocument, SHValueMatrix, SHValueScalar, SHValueVector } from '@shorthand/data';
import { AnyKeys } from './misc';

const __VERBOSE__ = false;

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export const breakpoints = [576, 768, 992, 1200];

export const spacing = (i: number) => `${i * 8}px`;
export const black = (opacity: number) => `rgba(0, 0, 0, ${opacity})`;
export const teal = (opacity: number) => `rgba(0, 198, 162, ${opacity})`;
export const white = (opacity: number) => `rgba(255, 255, 255, ${opacity})`;
export const charcoal = (opacity: number) => `rgba(100, 106, 116, ${opacity})`;

export const orange = (opacity: number) => `rgba(250, 120, 0, ${opacity})`;
export const deepBlue = (opacity: number) => `rgba(31, 46, 68, ${opacity})`;
export const blue = (opacity: number) => `rgba(73, 110, 165, ${opacity})`;
export const dangerColor = (opacity: number) => `rgba(245, 34, 45, ${opacity})`;
export const warningColor = (opacity: number) =>
  `rgba(250, 173, 20, ${opacity})`;
export const medGreen = (opacity: number) => `rgba(0, 201, 8, ${opacity})`;
export const limeGreen = (opacity: number) => `rgba(174, 244, 104, ${opacity})`;
export const mutedYellow = (opacity: number) =>
  `rgba(226, 228, 102, ${opacity})`;

export const grey = (opacity: number) => `rgba(171, 171, 171, ${opacity})`;
export const iMessageBlue = (opacity: number) => `rgba(20,126,251,${opacity})`;
export const SMSGreen = (opacity: number) => `rgba(83,215,105,${opacity})`;

export const primaryColor = orange;
export const emailStyle = { fontWeight: 'bold', color: primaryColor(1) } as any;
export const appBackground = (opacity = 0.1) => deepBlue(opacity)

const colors = {
  grays: {
    10: '#000',
    9: '#040404',
    8: '#404B5A',
    7: '#6E7A8A',
    6: '#929FB1',
    5: '#AEBECD',
    4: '#CFD8DF',
    3: '#E6EAED',
    2: '#EEF1F3',
    1: '#F7FAFC'
  }
};

const light = {
  bg: {
    primary: colors.grays[1],
    secondary: '#fff',
    inset: colors.grays[5],
    input: hexToRGB(colors.grays[5], 0.12)
  },
  text: {
    primary: colors.grays[9],
    secondary: colors.grays[9],
    tertiary: colors.grays[9],
    quarternary: colors.grays[9],
    placeholder: hexToRGB(colors.grays[9], 0.5),
    onPrimary: '#ffffff'
  }
};

const dark = light;

const sizes: FontSizeType = {
  xs: '0.6em',
  sm: '1.0em',
  md: '1.2em',
  lg: '1.8em',
  xl: '2.4em',
  xxl: '3em',
  xxxl: '3.6em',
  xxxxl: '4em'
};

export type FontSizeType = {
  xs: number | string;
  sm: number | string;
  md: number | string;
  lg: number | string;
  xl: number | string;
  xxl: number | string;
  xxxl: number | string;
  xxxxl: number | string;
};

const defaultTheme = {
  fontSizes: sizes,
  lineHeights: {
    body: 1.5,
    heading: 1.3,
    code: 1.6
  },
  breakpoints,
  spacing
};

export type Theme = {
  bg: AnyKeys;
  text: AnyKeys;
  lineHeights: AnyKeys;
  breakpoints: any[];
  spacing: (i: number) => string;
};

export const lightTheme = { ...defaultTheme, ...light };
export const darkTheme = { ...defaultTheme, ...dark };

export const gradients = {
  orange: `linear-gradient(135deg, #f6d365 0%, #fda085 100%)`,
  primary: `linear-gradient(135deg, #f6d365 0%, ${orange(1)} 100%)`,
  danger: `linear-gradient(135deg, ${dangerColor(0.5)} 0%, ${dangerColor(
    1
  )} 100%)`,
  pink: `linear-gradient(135deg, #f093fb 0%, #f5576c 100%)`,
  blue: `linear-gradient(135deg, #5ee7df 0%, #b490ca 100%)`,
  green: `linear-gradient(135deg, ${medGreen(1)} 0%, ${limeGreen(1)} 100%)`,
  lightgreen: `linear-gradient(135deg, ${limeGreen(1)} 0%, ${mutedYellow(
    1
  )} 100%)`,
  lightdanger: `linear-gradient(135deg, ${dangerColor(1)} -30%, ${mutedYellow(
    1
  )} 100%)`,
  grey: `linear-gradient(135deg, ${charcoal(1)} 0%, ${grey(1)} 100%)`,
  lightgrey: `linear-gradient(135deg, ${charcoal(0.2)} 0%, ${grey(0.2)} 100%)`
};

export const paneShadow = (x = 1, opacity = 1) =>
  `0px ${6 * x}px ${24 * x}px -${15 * x}px rgba(0, 0, 0, ${opacity})`;

export const insetShadow = (x = 1, opacity = 0.5) =>
  `0px 0px ${9 * x}px -${2 * x}px rgba(0, 0, 0, ${opacity})`;


export const portableShadow = (shadow: string) => `
  -webkit-box-shadow: ${shadow};
  -moz-box-shadow: ${shadow};
  box-shadow: ${shadow};
  transition: box-shadow 0.3s ease-in-out;
`;

export const portableInsetShadow = (shadow: string=insetShadow(1)) => `
  -webkit-box-shadow: ${insetShadow};
  -moz-box-shadow: ${insetShadow};
  box-shadow: ${insetShadow};
  transition: box-shadow 0.3s ease-in-out;
`;

export const hoverShadow = (x = 1, scale = 2) => `
  ${portableShadow(paneShadow(x))}
  :hover {
    ${portableShadow(paneShadow(scale * x))}
  };
`;


type ScaleTypes = 'linear' | 'logarithmic'

export const getLevelColorFactory = ({ low, high, mid, scale='linear' }: { low: number, mid?: number, high: number, scale: ScaleTypes }) => {
  const effectiveMid = (mid !== undefined) ? mid : (high + low) / 2
  return (val: number) => {
    if (val < low) {
      return 
    }
  }
}

export const linearIntervalIndexer = (min: number, max: number, n: number) => {
  const intvl = (max - min) / n
  return (x: number) =>  {
    const index = Math.round((x - min) / intvl )
    return Math.max(0, Math.min(n - 1, index))
  }
}

export const getValueBG = (doc:Partial<SHValueDocument>) => {
  const dims = doc?.vectorDimensions || getSHValueDimensions(doc?.value)
  const dimClass = classifySHDimensions(dims)

  switch (dimClass) {
    case 'MATRIX':
      return gradients.pink

    case 'VECTOR':
      return gradients.green

    case 'SCALAR':
      return getScalarValueBG(doc?.value as SHValueScalar)
  }
}

export const getScalarValueBG = (value: number | string | boolean | null | undefined) => {
  if (value === null) {
    __VERBOSE__ && console.log("null values")
    return gradients.lightgrey
  }
  if (value === undefined) {
    __VERBOSE__ && console.log("undefined values")
    return gradients.lightgrey
  }
  switch (typeof value) {
    case 'boolean':
      return gradients.lightgreen

    case 'string':
      return gradients.orange

    default:
      return gradients.blue
  }
}


export const getValueFont = (value: number | string | boolean) => {
  switch (typeof value) {
    case 'boolean':
      return 20

    case 'string':
      if (value.length > 5) {
        return 10
      }
      return 20

    default:
      return undefined
  }
}

export const getSHFunctionColor = (functionName: string) => {
  switch (functionName.toUpperCase()) {
    case 'SH.GET':
      return medGreen

    case 'SH.SET':
      return orange

    default:
      return deepBlue
  }
}

export const getSHFunctionArgColor = (arg: number | string | boolean) => {
  switch (typeof arg) {
    case 'number':
      return iMessageBlue
      
    case 'string':
      return orange

    case 'boolean':
      return limeGreen

    default:
      return charcoal
  }
}