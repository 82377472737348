import styled from '@emotion/styled';
import { SHFunctionArgConfig } from '@shorthand/call';
import { useSHFunction } from '@shorthand/hooks';
import { Divider, Input, Select, Space, Typography } from 'antd';
import { useState } from 'react';

const { Text, Title } = Typography

/* eslint-disable-next-line */
export interface SHFunctionEditorProps {}

const StyledSHFunctionEditor = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  height: 400px;
  margin: 30px;
  border: 2px solid grey;
  padding: 1rem;
  border-radius: 1rem;
`;

const ArgumentEditor = ({ arg }: { arg: SHFunctionArgConfig }) => {
  return (
    <Space>
      <Text>{ arg.name }</Text>
      <Text>{ arg.dataType }</Text>
      <Text>{ arg.required }</Text>
    </Space>
  )
} 

const ArgumentsEditor = ({ args }: { args: SHFunctionArgConfig[] }) => {
  return (
    <Space>
      { args.map(arg => (
          <ArgumentEditor key={arg.name} arg={arg}/>
      )) }
    </Space>
  )
} 

export const SHFunctionEditor = ({ functionID="testFunctionID" }) => {
  // const { data } = useSHFunction(functionID)
  const [ args, setArgs ] = useState<SHFunctionArgConfig[]>([
    {
      name: "initial arg",
      dataType: "STRING",
      required: false,
    },
    {
      name: "initial string",
      dataType: "STRING",
      required: false,
      defaultValue: "HOME RUBBER"
    },
    {
      name: "initial number",
      dataType: "NUMBER",
      required: false,
      defaultValue: 1000
    },
    {
      name: "initial matrix",
      dataType: "MATRIX",
      required: false,
      defaultValue: [[ 100, 200, 300 ]]
    },
    {
      name: "initial matrix",
      dataType: "ARRAY",
      required: false,
      defaultValue: [ 100, 200, 300 ]
    }
  ])

  const addArg = (arg: SHFunctionArgConfig) => {
    
  }


  const updateArg = (name: string) => (arg: SHFunctionArgConfig) => {
    setArgs(args.map(a => a.name === name ? arg : a ))
  }

  return (
    <StyledSHFunctionEditor>
      <h1>Test</h1>
      <Divider/>
      <Title 
        editable={{
          onChange: (s) => {
            console.log({ s })
          }
        }}
        level={3}>
        Function Name
      </Title>
      <Text
        editable={{
          onChange: (s) => {
            console.log({ s })
          }
        }}
        >
        Your Function Description Here
      </Text>
      <Text
        editable={{
          onChange: (s) => {
            console.log({ s })
          }
        }}
        >
        Your function URL here
      </Text>
      <Select>
        <Select.Option value={'POST'}>POST</Select.Option>
        <Select.Option value={'GET'}>GET</Select.Option>
      </Select>
      <ArgumentsEditor
        args={[]}
        />


    </StyledSHFunctionEditor>
  );
}

