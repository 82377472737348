
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";
import { DocumentSnapshot, FirestoreError, addDoc, collection, getFirestore } from "firebase/firestore"; 
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { getDatabase } from "firebase/database";
import _ from 'lodash'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCEb46rNSLa9sRaKZdInNNIGhQf91pitck",
//   authDomain: "shorthanddata.firebaseapp.com",
//   projectId: "shorthanddata",
//   storageBucket: "shorthanddata.appspot.com",
//   messagingSenderId: "638830305328",
//   appId: "1:638830305328:web:e8ff6629384be59ffa4df5",
//   measurementId: "G-V72L83YZ67",
//   databaseURL: "https://shorthanddata-default-rtdb.firebaseio.com/"
// };

// Initialize Firebase
export const app = initializeApp({
  apiKey: "AIzaSyCEb46rNSLa9sRaKZdInNNIGhQf91pitck",
  authDomain: "shorthanddata.firebaseapp.com",
  projectId: "shorthanddata",
  storageBucket: "shorthanddata.appspot.com",
  messagingSenderId: "638830305328",
  appId: "1:638830305328:web:e8ff6629384be59ffa4df5",
  measurementId: "G-V72L83YZ67",
  databaseURL: "https://shorthanddata-default-rtdb.firebaseio.com/",
});

// export const analytics = getAnalytics(app);
export const rtdb = getDatabase(app)

export const firestore = getFirestore(app);

// firestore.settings({ ignoreUndefinedProperties: true })

export { addDoc, collection }

export const auth = getAuth(app);

onAuthStateChanged(auth, user => {
  // Check for user status
});

export type QueryInfoTail = [boolean, FirestoreError | undefined]
export const collapseInfos = <T=any>(infos: ([any, T | undefined])[]) => {
  const loading = infos.reduce((acc, curr) => acc || !!curr[0], false)
  const error = infos.reduce((acc, curr) => acc || curr[1], undefined as (T | undefined))
  return ({
    loading, 
    error
  })
}