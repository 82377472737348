import styled from "@emotion/styled";
import { Space, Tag, Typography } from "antd";
import moment from "moment";
import { getFunctionCallURL } from "@shorthand/call";
import { useAuthUserContext, useSHFunctionCallHistory } from '@shorthand/hooks'
import { SHValueBadge } from "@shorthand/atoms"

const { Title, Text } = Typography

export const SHFunctionCallHistory = ({ functionID }: { functionID: string }) => {
  const { items } = useSHFunctionCallHistory(functionID)
  const { domainID } = useAuthUserContext()

  return (
    <TopicHistoryContainer>
      <RowsContainer>
      { items?.map((i, k) => (
        <TopicRowValueHistoryContainer key={`${i.id || k}`} href={getFunctionCallURL({ domainID })({ functionCallID: i.id })}>
          <Space>
            <Text style={{ opacity: i.id === 'latest' ? 1 : 0.5, fontSize: 10 }}>
              { moment.unix(i.clientInitiatedTS / 1000).format('L hh:mm A') }
            </Text>
            <Text>
              {
                i.executor?.email || '---'
              }
            </Text>
          </Space>
          <SHValueBadge
            value={'SUCCESS'}
            />
        </TopicRowValueHistoryContainer>
      ))}
      </RowsContainer>
    </TopicHistoryContainer>
  )
}

const TopicRowValueHistoryContainer = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 4px;
  max-height: 100%;
`

const TopicHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-height: 70px;
`