import { CopyableCodeBlock } from '@shorthand/atoms'
import styled from "@emotion/styled"
import { UseSelectedRangeValueMinimal } from '@shorthand/hooks'
import { classifySHDimensions, getSHValueDimensions, SHValueData, SHValueDataContent, SHValueDocument, valueBadgeShortString } from "@shorthand/data"

import { Button, Space, Tabs, Tag, Tooltip, Typography } from "antd"
import { medGreen, orange } from "@shorthand/theme";
import _ from 'lodash'
import { CheckCircleOutlined, CloseCircleOutlined, SubnodeOutlined, FunctionOutlined, HistoryOutlined, LoadingOutlined, SettingOutlined } from "@ant-design/icons";
import { useFormulaBuilderContext } from "@shorthand/hooks";
import { SHValueBadge } from '@shorthand/atoms'
import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import { TopicHistory } from "../topic-history/topic-history";
const { Title, Text } = Typography 

export const TopicCardView = (
  { prefixTags, data, style, update, ...hook }: 
 UseSelectedRangeValueMinimal & { prefixTags?: ReactNode, data?: SHValueDocument, style?: React.CSSProperties, update?: (a: Partial<SHValueDataContent>) => Promise<any>  }) => (
  // @ts-ignore
  <Container style={style as CSSProperties}>
    <TopicCardContent 
      update={update}
      prefixTags={prefixTags}
      {...hook}
      data={data}/>
  </Container>
)

const Container = styled.div`
  background: white;
  /* height: 40px; */
  /* display: flex; */
  /* width: 100%; */
  max-width: 100%;
  padding: 10px;
  height: 100%;
  
  border-radius: 4px;
`

const ContentContainer = styled.div`
  /* width: 100%; */
  max-width: 100%;
`

export const TopicCardContent = ({ 
  data, 
  prefixTags, 
  update,
  ...hook
}: 
  UseSelectedRangeValueMinimal & { 
  data?: SHValueDocument, 
  prefixTags?: ReactNode, 
  update?: (a: Partial<SHValueDataContent>) => Promise<any>,

}) => {
  const key = `"latest"`;
  const { insertIntoSelection, selectedAddress, fillSelection } = hook
  const getFormula = `=SH.GET("${data?.id || 'loading'}")`;
  
  const { mode, setMode } = useFormulaBuilderContext()
  const [ actionState, setActionState ] = useState<ReactNode>()
  const [ newDescription, setNewDescription ] = useState<string>()

  useEffect(() => {
    if (actionState) {
      const to = setTimeout(() => setActionState(undefined), 1500)
      return () => clearTimeout(to)
    }
    return
  }, [actionState])

  return (
    <ContentContainer>
      <TitleSection>
        <TopRow>
          { prefixTags }
          <Tag color={orange(1)} style={{ maxWidth: 150 }}>
          { data?.id } 
          </Tag>
          {/* <FunctionMenu/> */}
          <SHValueBadge
            value={data?.value}
            />

        </TopRow>
        <Tooltip title={data?.description || "No description provided"}>
          <Text 
            style={{ 
              fontStyle: 'italic',
               margin: 0, 
               marginTop: 3, 
              //  opacity: (!data?.description || _.isEmpty(data.description)) ? 0.4 : 1.0
              //  height: 40 
            }} 
            editable={{
              onChange: async (s) => {
                setNewDescription(s)
                setActionState(
                  <LoadingOutlined style={{ marginRight: 5 }}/>
                )
                try {
                  update && await update({
                    description: s
                  })
                  setActionState(
                    <CheckCircleOutlined style={{ color: 'green', marginRight: 5 }}/>
                  )
                } catch(e) {
                  console.error(e)
                  setActionState(
                    <CloseCircleOutlined style={{ color: 'red', marginRight: 5 }}/>
                  )
                } finally {
                  setNewDescription(undefined)
                }
              },
              onEnd: async () =>  {
                return
              }
            }}
            // @ts-ignore
            ellipsis={{ rows: 2, }}>
            { actionState }
            { newDescription || data?.description || <span style={{ opacity: 0.4 }}>No description provided</span> }
          </Text>
        </Tooltip>
      </TitleSection>

      <ModeSelection mode={mode} setMode={setMode}/>
      
      <TabPaneBasic isVisible={mode === 'GET'}>
        <TopicContentRow>
          <CodeBlockContainer>
            <CopyableCodeBlock
              actions={[
                {
                  name: 'Copy topic name to clipboard',
                  icon: <SubnodeOutlined/>,
                  after: 'Topic name copied!',
                  onAction: async () => {
                    data?.id && navigator?.clipboard?.writeText(data?.id)
                  }
                }
              ]}
              formula={getFormula}
              />
          </CodeBlockContainer>
        </TopicContentRow>
      </TabPaneBasic>

      <TabPaneBasic isVisible={mode === 'SET'}>
        <TopicContentRow>
          <CodeBlockContainer>
            <CopyableCodeBlock
              formula={`=SH.SET("${data?.id}", ${selectedAddress || ''})`}
              />
          </CodeBlockContainer>
        </TopicContentRow>
      </TabPaneBasic>
      <TabPaneBasic isVisible={mode === 'CALL'}>
        <TopicContentRow>
          <CodeBlockContainer>
            <CopyableCodeBlock
              actions={[
                // {
                //   name: 'Inject',
                //   icon: <FunctionOutlined/>,
                //   after: 'Injected!',
                //   onAction: () => {
                //     const formula = `=SH.CALLV("${data?.id}", ${selectedAddress || ''})`
                //     return insertIntoSelection([[ formula ]])
                //   }
                // }
              ]}
              formula={`=SH.CALLV("${data?.id}", ${selectedAddress || ''})`}
              />
          </CodeBlockContainer>
        </TopicContentRow>
      </TabPaneBasic>

      <TabPaneBasic isVisible={mode === 'HISTORY'}>
        { data?.id ? <TopicHistory topicID={data?.id}/> : '--'}
      </TabPaneBasic>

      <TabPaneBasic isVisible={mode === 'SETTINGS'}>
        <Text style={{ opacity: 0.5 }}>Under Construction</Text>
      </TabPaneBasic>
      
    </ContentContainer>
  )
}
const CenteredIcon = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const MODES = [
  {
    tab: <span style={{ color: medGreen(1) }}>GET</span>,
    key: "GET"
  },
  {
    tab: <span style={{ color: orange(1) }}>SET</span>,
    key: "SET"
  },
  // {
  //   tab: <span style={{ color: teal(1) }}>CALL</span>,
  //   key: "CALL"
  // },
  {
    tab: <CenteredIcon><HistoryOutlined/></CenteredIcon>,
    key: "HISTORY"
  },
  {
    tab: <CenteredIcon><SettingOutlined/></CenteredIcon>,
    key: "SETTINGS"
  },
]

export const TabPaneBasic = ({ children, isVisible }) => {
  if (!isVisible) {
    return null
  }
  return (
    children
  )
}

const ModeSelection = ({ mode, setMode }) => {
  return (
    <Space style={{ marginTop: 5, marginBottom: 5 }}>
      {
        MODES.map(m => (
          <a 
            key={m.key} 
            onClick={() => setMode(m.key)} 
            style={{ opacity: mode === m.key ? 1 : 0.5, fontWeight: mode === m.key ? 'bold' : 'inherit' }}>
            { m.tab }
          </a>
        ))
      }
    </Space>
  )
}

const CodeBlockContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  flex-direction: column;
`

const TopicContentRow = styled.div<any>`
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  display: flex;
`
const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`

const TitleSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: -4px;
`