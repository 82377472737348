import { SHUserConverter, USER_DOC_COLLECTION_NAME } from "@shorthand/domain";
import { auth, firestore } from "@shorthand/firebase";
import { doc } from "firebase/firestore";
import { createContext, useContext, ReactNode } from "react"
import { useAuthState, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useDocumentData } from "react-firebase-hooks/firestore";

export const useSHSignInWithEmailAndPassword = () => {
  const [
    signIn,
    user,
    loading,
    error,
  ] = useSignInWithEmailAndPassword(auth);

  return ({
    signIn,
    user,
    loading,
    error,
  })
}

export function useAuthUser() {

  const [ user, ...authState ] = useAuthState(auth);

  const userDocRef = doc(firestore, `${USER_DOC_COLLECTION_NAME}/${user?.uid || 'na'}`).withConverter(SHUserConverter)
  const [ userDoc, ...userDocState ] = useDocumentData(userDocRef)

  const signOut = async () => {
    return await auth.signOut()
  }

  return ({
    signOut,
    user, 
    loading: authState[0] || userDocState[1], 
    error: authState[1] || userDocState[1],
    domainID: userDoc?.primaryDomainID || 'na',
    uid: user?.uid,
  })
}

export const AuthUserContext = createContext({} as any as ReturnType<typeof useAuthUser>)

export const AuthUserContextProvider = ({ children }: { children: ReactNode }) => {
  const value = useAuthUser()
  return (
    <AuthUserContext.Provider value={value}>
      { children }
    </AuthUserContext.Provider>
  )
}

export const useAuthUserContext = () => {
  return useContext(AuthUserContext)
}
export const withAuthUserContext = (f: (p: any) => ReactNode) => (
  props: any
) => <AuthUserContextProvider>{f(props)}</AuthUserContextProvider>;
