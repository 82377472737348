import { getSHFunctionArgColor, getSHFunctionColor } from "@shorthand/theme"
import { Space, Typography, Tag } from "antd"

const { Title, Text } = Typography

type SHFunctionCallInfoProps = {
  functionName: string,
  args: (string | number | boolean)[]
}

export const SHFunctionCallInfo = ({ functionName, args }: SHFunctionCallInfoProps) => {
  return (
    <Text>
      <span style={{ color: getSHFunctionColor(functionName)(1) }}>
        { functionName }
      </span>
      <Space>
        (
        {
          args.map((arg) => (
            <span key={`${arg}`}>
              <Tag
                color={getSHFunctionArgColor(arg)(1)}
                title={`${arg}`}
                >
                { arg }
              </Tag>
            </span>
          ))
        }
        )
      </Space>
    </Text>
  )
}