import styled from "@emotion/styled";
import { SHTopicHistoryHookValue, useSHTopicHistory } from "@shorthand/hooks";
import { Popover, Space, Tag, Typography, Button, message, Modal, Input, Drawer } from "antd";
import moment from "moment";
import { SHValueBadge } from "@shorthand/atoms";
import { isLikelyTimestamp } from "@shorthand/utils";
import { PlusCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import _ from 'lodash'
import { warningColor } from "@shorthand/theme";

const { Title, Text } = Typography

export const TopicHistory = ({ topicID, domainID }: { topicID: string, domainID?: string }) => {
  const { items, setAsLatest, cloneValueWithName } = useSHTopicHistory(topicID, { domainID })

  return (
    <TopicHistoryContainer>
      <RowsContainer>
      { items?.map((i, k) => (
        <TopicRowValueHistoryContainer key={`${i.id || k}`}>
          <Space>
            <Popover
              content={
                <>
                  <TopicValueTagPopoverContent 
                    cloneValueWithName={cloneValueWithName}
                    setAsLatest={setAsLatest}
                    tagID={i.id}/>
                </>
              }
              >
              <Tag color={
                !isLikelyTimestamp(i.id) ? 'red' : undefined
              }>
                <Text 
                  style={{ opacity: i.id === 'latest' ? 1 : 0.5, fontSize: 10 }}
                  // @ts-ignore
                  ellipsis={{ rows: 1 }}
                  >
                  { isLikelyTimestamp(i.id) ? moment.unix((i!.updatedTS || 0) / 1000).format('L hh:mm A') : i.id }
                </Text>
              </Tag>
            </Popover>

            <Text>
              {
                i?.author?.email || '---'
              }
            </Text>
          </Space>
          <SHValueBadge 
            value={i?.value}
            />
        </TopicRowValueHistoryContainer>
      ))}
      </RowsContainer>
    </TopicHistoryContainer>
  )
}

const TopicValueTagPopoverContent = ({ tagID, setAsLatest, cloneValueWithName }: Partial<SHTopicHistoryHookValue> & { tagID: string }) => {
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ newTagName, setNewTagName ] = useState('')
  const errorMessage = (!_.isEmpty(newTagName) && !newTagName.match(/^[a-z0-9]+$/i)) ? 'Value tag name can only contain alphanumeric characters' : undefined
  const warningMessage = _.isEmpty(newTagName) ? 'Tag name must be non-empty' : undefined
  return (
    <>
      {
        modalOpen && (
          <Drawer title='New topic tag' visible={modalOpen} footer={null} onClose={() => setModalOpen(false)} placement='bottom'>
            <div>
              <Input placeholder="New tag name" value={newTagName} onChange={(e) => setNewTagName(e.target.value)}/>
              <Text style={{ color: 'red' }}>
                { errorMessage }
              </Text>
              <Text style={{ color: warningColor(1) }}>
                { !errorMessage && warningMessage }
              </Text>
              <Button 
                block 
                style={{ marginTop: 10 }}
                type='primary'
                disabled={!!(errorMessage || warningMessage)}
                onClick={async () => {
                  try {
                    message.info(`Tagging ${tagID} -> ${newTagName}`)
                    cloneValueWithName && newTagName && await cloneValueWithName(tagID, newTagName)
                    message.success(`${newTagName} created!`)
                    setModalOpen(false)
                  } catch(e) {
                    message.error(`Tagging [${tagID} -> ${newTagName}] failed; please contact support.`)
                  }
                }}
                >
                Add
              </Button>
            </div>
          </Drawer>
        )
      }
      <TopicValueTagPopover>
        <Space>
          <Button 
            size='small'
            disabled={(tagID === 'latest') || !setAsLatest} 
            danger
            onClick={async () => {
              try {
                message.info(`Promoting ${tagID}`)
                setAsLatest && await setAsLatest(tagID)
                message.success(`${tagID} promoted to master/latest`)
              } catch(e) {
                console.error(e)
                message.error("Promotion failed; please contact support.")
              }
            }}>
            <UpCircleOutlined/>
            Promote
          </Button>
          <Button
            onClick={() => {
              setModalOpen(true)
            }}
            size='small'
            type='primary'
            >
            <PlusCircleOutlined/> Tag
          </Button>
        </Space>
      </TopicValueTagPopover>
    </>
  )
}

const TopicValueTagPopover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TopicRowValueHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 4px;
  position: absolute;
  top: 0;
  right: -17px;
  left: 0;
  bottom: 0;
  max-height: 100%;
`

const TopicHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 70px;
  max-height: 70px;
`