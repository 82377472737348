export default {
  brand: 'shorthand.ai',
  tagline: 'Tooling that Understands You',
  description:
    'Shorthand provides the fastest, easiest tools for rich behavioral data collection.',
  site_url: 'https://shorthand.ai',
  site_domain: 'shorthand.ai',
  support_email: 'help@shorthand.ai',
  volunteer_email: 'volunteer@shorthand.ai',
  team_email: 'team@shorthand.ai',
  sales_email: 'sales@shorthand.ai'
};

export const VERSION_STRING = '1.0.1.63'