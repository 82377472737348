import { SHValueScalar } from '@shorthand/data';
import { useState, useCallback } from 'react';
import { ASTTreeNode } from '@shorthand/excel';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseSelectedRange {
  count: number;
  increment: () => void;
}

export function useSelectedRange(): UseSelectedRange {
  const [count, setCount] = useState(0);
  const increment = useCallback(() => setCount((x) => x + 1), []);
  return { count, increment };
}

export default useSelectedRange;

type ExcelRangeCellContent = {
  formula: string;
  address: string;
  addressLocal: string;
  parsedFunction: {
      argsExpressions: string[];
      raw: string;
      type: "function";
      name: string;
      arguments: ASTTreeNode[];
  };
}

export type SelectedRangeFiller = (f: (i: number, j: number) => SHValueScalar | undefined) => void
export type SelectedRangeValueMatrix = any[][]
export type SelectedRangeValueSetter = (mat: SelectedRangeValueMatrix) => void

export type UseSelectedRangeValue = {
  fillSelection: SelectedRangeFiller,
  insertIntoSelection: SelectedRangeValueSetter,
  selectedRangeDimension: {
    rowCount: number,
    colCount: number
  },
  loading: boolean
  activeSheetName?: string,
  selectedAddress?: string,
  selectedAddressLocal?: string,
  selectedRangeContentMatrix: ExcelRangeCellContent[][]
  selectedRangeContentFlat: ExcelRangeCellContent[],
  rangeChangeIndex: number
} 


export type UseSelectedRangeValueMinimal = {
  fillSelection: SelectedRangeFiller
  insertIntoSelection: SelectedRangeValueSetter
  selectedAddress: string
}