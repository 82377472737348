import styled from '@emotion/styled';
import { useAuthUserContext, useSHSignInWithEmailAndPassword } from '@shorthand/hooks';
import { appBackground } from '@shorthand/theme';
import { validateEmail } from '@shorthand/utils';
import _ from 'lodash';
import { Input, Button, message, Divider } from 'antd';
import { primaryColor } from '@shorthand/theme';
import { useEffect, useState } from 'react';
import { AppLogoStack, LogoStack } from '@shorthand/atoms';
import brand, { VERSION_STRING } from '@shorthand/theme/brand';
import { animated, useSpring } from 'react-spring';
import { signInWithEmailAndPassword } from 'firebase/auth';

/* eslint-disable-next-line */
export interface LoginProps {}

const StyledLogin = styled.div`
  max-height: 400px;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 350px;
  margin: 1rem;
  background: white;
`;

export function Login(props: LoginProps) {
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const inputsValid = validateEmail(email) && !_.isEmpty(password)
  const { user, signIn, loading, error } = useSHSignInWithEmailAndPassword()

  const clearInputs = () => {
    setPassword('')
    setEmail('')
  }
  
  const onSignIn = async () => {
    try {
      message.info("Signing in...")
      const _user = await signIn(email, password)
      if (!_user) {
        message.error(`Sign in unsuccessful; please check your credentials and try again.`)
      } else {
        clearInputs()
        message.success(`Signed in as ${email}`)
      }
      
    } catch(e: any) {
      console.error(e)
      message.error("Sign in unsuccessful. Please check your credentials.")
    }
  }

  return (
    <StyledLogin>
      <AppLogoStack
        target={"_blank"}
        emph={`v${VERSION_STRING}`}
        href={brand.site_url}
        appName="ShorthandAI"
        />
      <br/>
      <form onSubmit={onSignIn}> 
        <Input
          style={{ marginBottom: 10 }}
          value={email}
          placeholder='Email'
          onChange={(e) => setEmail(e.target.value)}
          />
        <Input
          style={{ marginBottom: 10 }}
          value={password}
          placeholder='Password'
          type='password'
          onChange={(e) => setPassword(e.target.value)}
          />

        <Button block type='primary' disabled={!inputsValid} onClick={() => onSignIn()}>
          Sign In
        </Button>
      </form>
      <p style={{ fontSize: 'smaller', opacity: 0.5, marginTop: 10, marginBottom: 0 }}>
        v{ VERSION_STRING }
      </p>
    </StyledLogin>
  );
}

const OverlayContainer = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${appBackground(1)};
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LoginOverlay = ({ children }) => {
  const { user } = useAuthUserContext()
  const containerStyle = useSpring({
    top: user ? '100vh' : '0vh',
    bottom: user ? '-100vh' : '0vh',
  })

  return (
    <div>
      <OverlayContainer style={containerStyle}>
        <Login/>
      </OverlayContainer>
      { user ? children : null }
    </div>
    
  )
}

export default Login;
